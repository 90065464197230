import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  MenuItem,
  TextField,
  FormControlLabel,
  Input,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { isEqual, keys, merge, isEmpty, pick, pickBy } from 'lodash';
import Form from '../../../components/Form';
import Translate from '../../../components/service/Translate';
import { initEmailForm, loadSeason, loadSeasons } from '../actions';
import { loadLayouts } from '../../EmailLayout/actions';
import { loadSelections } from '../../Selection/actions';
import { loadEmailTags } from '../actions';
import { makeSelectableSelections } from '../../Selection/selectors';
import { loadEmailBySeason } from '../../Email/actions';
import { makeSeasonById } from '../selectors_deprecated';
import { outdateEntity, resetEntity } from '../../../actions';
import { withRouter } from '../../../withRouter';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import ValidationRules from '../../../ValidationRules';
import FormHelperText from '@mui/material/FormHelperText';
import {
  fetchAllEmailAccounts,
  selectAllEmailAccounts,
} from '../../EmailAccount/EmailAccountSlice';
import EmailEditor from 'react-email-editor';
import Switch from '@mui/material/Switch';
import Config from '../../../utils/EmailEditor/Config';
import Variables from '../../../utils/EmailEditor/Variables';
import LinkTypes from '../../../utils/EmailEditor/LinkTypes';
import { Autocomplete } from '@mui/material';
import { loadList } from '../../EmailModule/actions';
import { makeTextModulesBySeasonIdForMergeTags } from '../../MailingModule/selectors_deprecated';
import { loadMailingModulesByMode } from '../../MailingModule/actions';
import DisplayConditionDialog from './DisplayConditionDialog';
import {
  EmptyMenuItem,
  CheckboxField,
} from '../../../components/StyledElements/StyledElements';
import { compose } from 'redux';
import Grid from '@mui/material/Unstable_Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import { withTheme } from '../../../withTheme';
import { DatePicker } from '@mui/x-date-pickers';
import { parseDate } from '../../../utils/HelperFunctions';

const validationSchema = Yup.object().shape({
  campaign: Yup.object().shape({
    campaignKey: Yup.string().when('isChild', {
      is: true,
      then: () => Yup.string().nullable(),
      otherwise: () => Yup.string().required(ValidationErrors.required),
    }),
  }),
  season: Yup.object().shape({
    archived: Yup.string().nullable(),
    configKey: Yup.string()
      .matches(ValidationRules.configKeyRegex, {
        message: ValidationErrors.configKeyRegexMessage,
      })
      .required(ValidationErrors.configKeyRegexMessage),
    selection: Yup.string().when('parent', {
      is: (val) => !!val,
      then: () => Yup.string().required(ValidationErrors.required),
      otherwise: () => Yup.string().nullable(),
    }),
    cycle: Yup.string().oneOf(
      ['monthly', 'singular', 'consecutively'],
      ValidationErrors.requiredSelect,
    ),
    combined: Yup.bool(),
    parent: Yup.string().nullable(),
  }),
  account: Yup.mixed().when('accountsAvailable', {
    is: true,
    then: () => Yup.string().required(ValidationErrors.required),
    otherwise: () => Yup.string().nullable(),
  }),
  locationAware: Yup.bool(),
  personalizedTracking: Yup.bool(),
  approvalPeriod: Yup.mixed().when('season.cycle', {
    is: (val) => val && val === 'monthly',
    then: () =>
      Yup.number()
        .required(ValidationErrors.required)
        .positive(ValidationErrors.positiveIntNumber)
        .integer(ValidationErrors.positiveIntNumber)
        .typeError(ValidationErrors.positiveIntNumber),
    otherwise: () => Yup.string().nullable(),
  }),
  printPeriod: Yup.mixed().when('season.cycle', {
    is: (val) => val && val === 'monthly',
    then: () =>
      Yup.number()
        .required(ValidationErrors.required)
        .positive(ValidationErrors.positiveIntNumber)
        .integer(ValidationErrors.positiveIntNumber)
        .typeError(ValidationErrors.positiveIntNumber),
    otherwise: () =>
      Yup.mixed().when('season.cycle', {
        is: (val) => val && val === 'singular',
        then: () =>
          Yup.date()
            .transform(ValidationRules.parseDateString)
            .typeError(ValidationErrors.invalidDateFormat)
            .required(ValidationErrors.required),
        otherwise: () => Yup.string().nullable(),
      }),
  }),
  //copyReferences: Yup.string().nullable(),
  copyReferences: Yup.array().of(Yup.string()).nullable(),
  feeMail: Yup.number()
    .transform((_value, originalValue) =>
      Number(originalValue.toString().replace(/,/, '.')),
    )
    .typeError(ValidationErrors.typeErrorNumberWithComma)
    .required(ValidationErrors.required),
  masterTemplate: Yup.object()
    .shape({
      name: Yup.string().required(ValidationErrors.required),
      addresser: Yup.string().nullable(),
      subject: Yup.string().required(ValidationErrors.required),
      body: Yup.string().required(ValidationErrors.required),
      txtBody: Yup.string().nullable(),
      embeddedEditor: Yup.bool(),
      layout: Yup.string().when('embeddedEditor', {
        is: (val) => !!val,
        then: () => Yup.string().nullable(),
        otherwise: () => Yup.string().required(ValidationErrors.required),
      }),
    })
    .nullable(),
});

class SeasonEdit extends Component {
  editor = createRef();

  constructor(props) {
    super(props);
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.formikProps = null;
    this.done = null;
  }

  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    seasonType: PropTypes.string.isRequired,
    seasonId: PropTypes.number,
    loadSeason: PropTypes.func.isRequired,
    loadSeasons: PropTypes.func.isRequired,
    fetchAllEmailAccounts: PropTypes.func.isRequired,
    loadSelections: PropTypes.func.isRequired,
    loadEmailTags: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onValidityChange: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadLayouts: PropTypes.func,
    loadEmailBySeason: PropTypes.func,
    campaign: PropTypes.object,
    season: PropTypes.object,
    seasons: PropTypes.object,
    modules: PropTypes.object,
    mailingModules: PropTypes.object,
    selections: PropTypes.object,
    channel: PropTypes.object,
    layouts: PropTypes.object,
    values: PropTypes.object,
    token: PropTypes.string,
    tags: PropTypes.object,
    account: PropTypes.object,
  };

  static defaultProps = {
    values: {},
  };

  state = {
    embeddedEditorSwitchTouched: false,
    previousBody: undefined,
    conditionsDialog: false,
    modulesLoaded: false,
    templateLoaded: false,
  };

  componentDidMount = () => {
    this.props.outdateEntity('selection');
    this.props.loadSelections(null, null, ['season'], this.props.seasonId);
    this.props.loadSeasons([], [], ['isParent']);
    Promise.all([this.props.loadList()]).then(() => {
      this.setState({ modulesLoaded: true });
    });
    this.props.loadMailingModulesByMode('text');

    switch (this.props.seasonType) {
      case 'email':
        this.props.loadLayouts();
        this.props.fetchAllEmailAccounts();
        if (this.props.seasonId) {
          this.props.loadEmailBySeason(this.props.seasonId, [
            'masterTemplate',
            'template',
          ]);
        } else {
          this.props.initEmailForm();
        }
        break;
    }

    this.setState({ emailTagsLoading: true });
    Promise.all([this.props.loadEmailTags()]).then(
      // eslint-disable-next-line no-unused-vars
      (value) => {
        this.setState({ emailTagsLoading: false });
      },
    );

    if (this.props.seasonId) {
      this.setState({ seasonLoading: true });
      Promise.all([this.props.loadSeason(this.props.seasonId)]).then(
        // eslint-disable-next-line no-unused-vars
        (value) => {
          this.setState({ seasonLoading: false });
        },
      );
    }
    this.isComponentMounted = true;
    setTimeout(this.loadTemplate, 1);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.seasonId !== prevProps.seasonId) {
      this.props.loadSeason(this.props.seasonId);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.seasonId, this.props.seasonId) ||
      !isEqual(nextProps.channel, this.props.channel) ||
      !isEqual(nextProps.account, this.props.account) ||
      !isEqual(nextProps.seasonType, this.props.seasonType) ||
      !isEqual(nextProps.campaignId, this.props.campaignId) ||
      !isEqual(nextProps.season, this.props.season) ||
      !isEqual(nextProps.selections, this.props.selections) ||
      !isEqual(nextProps.form, this.props.form) ||
      !isEqual(nextProps.campaign, this.props.campaign) ||
      !isEqual(nextProps.token, this.props.token) ||
      !isEqual(
        nextState.embeddedEditorSwitchTouched,
        this.state.embeddedEditorSwitchTouched,
      ) ||
      !isEqual(nextState.modulesLoaded, this.state.modulesLoaded) ||
      !isEqual(nextState.templateLoaded, this.state.templateLoaded) ||
      !isEqual(nextProps.tags, this.props.tags) ||
      !isEqual(nextProps.loading, this.props.loading) ||
      !isEqual(nextState.conditionsDialog, this.state.conditionsDialog) ||
      !isEqual(nextState.seasonLoading, this.state.seasonLoading) ||
      !isEqual(nextState.emailTagsLoading, this.state.emailTagsLoading) ||
      !isEqual(nextProps.layouts, this.props.layouts)
    );
  };

  renderEmailFieldset = (props) => {
    const { season } = this.props;
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } =
      props;

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Customer list</Translate>
          </Typography>
        </Grid>
        {this.renderSelectionSelect(props)}
        {this.renderAutocomplete(props)}
        <Grid xs={12} sm={6}>
          <TextField
            id={'copyReferences'}
            label={<Translate>Reference group for job patterns</Translate>}
            defaultValue={
              values.copyReferences
                ? Array.isArray(values.copyReferences)
                  ? values.copyReferences.join(',')
                  : values.copyReferences
                : ''
            }
            name={'copyReferences'}
            onChange={(e) =>
              props.setFieldValue('copyReferences', e.target.value.split(','))
            }
            onBlur={handleBlur}
            helperText={touched.copyReferences ? errors.copyReferences : ''}
            error={touched.copyReferences && Boolean(errors.copyReferences)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormCheckbox
            name="season[combined]"
            label={<Translate>combined</Translate>}
            checked={props.values?.season?.combined ?? false}
            setFieldValue={setFieldValue}
            error={
              props.touched?.season?.combined && props.errors?.season?.combined
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormCheckbox
            name="locationAware"
            label={<Translate>Location aware</Translate>}
            checked={props.values?.locationAware ?? false}
            setFieldValue={setFieldValue}
            error={props.touched?.locationAware && props.errors?.locationAware}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Timing</Translate>
          </Typography>
        </Grid>
        {values.season && values.season.cycle === 'singular' ? (
          <Grid xs={12} sm={6}>
            <Stack direction="row" spacing={3}>
              <DatePicker
                label={<Translate>Approval date</Translate>}
                format="dd.MM.yyyy"
                value={
                  values.approvalPeriod
                    ? parseDate(values.approvalPeriod)
                    : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'approvalPeriod',
                    date.toLocaleString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }),
                  )
                }
                slotProps={{
                  textField: {
                    helperText: errors.approvalPeriod,
                  },
                }}
              />
              <DatePicker
                label={<Translate>Print date</Translate>}
                format="dd.MM.yyyy"
                value={
                  values.printPeriod ? parseDate(values.printPeriod) : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'printPeriod',
                    date.toLocaleString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }),
                  )
                }
                slotProps={{
                  textField: {
                    helperText: errors.printPeriod,
                  },
                }}
              />
            </Stack>
          </Grid>
        ) : values.season && values.season.cycle === 'consecutively' ? (
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Delay Interval</Translate>}
              defaultValue={
                season.delayInterval ? season.delayInterval.toString() : ''
              }
              name={'season[delayInterval]'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.season && touched.season && touched.season.delayInterval
                  ? errors.season.delayInterval
                  : ''
              }
              error={
                errors.season &&
                touched.season &&
                touched.season.delayInterval &&
                Boolean(errors.season.delayInterval)
              }
            />
          </Grid>
        ) : (
          <>
            <Grid xs={12} sm={6}>
              <TextField
                label={<Translate>Approval period</Translate>}
                defaultValue={
                  values.approvalPeriod ? values.approvalPeriod.toString() : ''
                }
                name={'approvalPeriod'}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.approvalPeriod ? errors.approvalPeriod : ''}
                error={touched.approvalPeriod && Boolean(errors.approvalPeriod)}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label={<Translate>Print period</Translate>}
                defaultValue={
                  values.printPeriod ? values.printPeriod.toString() : ''
                }
                name={'printPeriod'}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.printPeriod ? errors.printPeriod : ''}
                error={touched.printPeriod && Boolean(errors.printPeriod)}
              />
            </Grid>
          </>
        )}

        <Grid xs={12} sm={6}>
          <TextField
            select
            id={'season[cycle]'}
            label={<Translate>cycle</Translate>}
            name={'season[cycle]'}
            value={
              values.season && values.season.cycle
                ? values.season.cycle
                : 'monthly'
            }
            onChange={(e) => {
              handleChange(e);
              if (e.target.value === 'consecutively') {
                props.setFieldValue('approvalPeriod', '');
                props.setFieldValue('printPeriod', '');
              } else {
                props.setFieldValue('printPeriod', '');
              }
            }}
            onBlur={handleBlur}
            helperText={
              touched.season && errors.season && touched.season.cycle
                ? errors.season.cycle
                : ''
            }
            error={
              touched.season &&
              errors.season &&
              touched.season.cycle &&
              Boolean(errors.season.cycle)
            }
          >
            <MenuItem value={'monthly'}>
              {' '}
              {<Translate>monthly</Translate>}{' '}
            </MenuItem>
            <MenuItem value={'singular'}>
              {' '}
              {<Translate>singular</Translate>}{' '}
            </MenuItem>
            <MenuItem value={'consecutively'}>
              {' '}
              {<Translate>consecutively</Translate>}{' '}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <DatePicker
            label={<Translate>Archived</Translate>}
            format="dd.MM.yyyy"
            value={
              values.season.archived ? parseDate(values.season.archived) : null
            }
            onChange={(date) =>
              setFieldValue(
                'season[archived]',
                date.toLocaleString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }),
              )
            }
            slotProps={{
              textField: {
                helperText: errors.season?.archived,
              },
            }}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Pricing</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            id={'feeMail'}
            label={<Translate>Dispatch</Translate>}
            defaultValue={values.feeMail ? values.feeMail.toString() : ''}
            name={'feeMail'}
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              props.setFieldValue(
                'feeMail',
                props.values.feeMail.toString().replace(/,/g, '.'),
              );
            }}
            helperText={touched.feeMail ? errors.feeMail : ''}
            error={touched.feeMail && Boolean(errors.feeMail)}
          />
        </Grid>
        {this.renderMasterTemplate(props)}
      </>
    );
  };

  renderMasterTemplate = (props) => {
    const { handleChange, handleBlur, values, touched, errors } = props;

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Master template</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            id={'masterTemplate[name]'}
            label={<Translate>Name</Translate>}
            defaultValue={
              values && values.masterTemplate ? values.masterTemplate.name : ''
            }
            name={'masterTemplate[name]'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.name
                ? errors.masterTemplate.name
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.name &&
              Boolean(errors.masterTemplate.name)
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <CheckboxField>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    values &&
                    values.masterTemplate &&
                    typeof values.masterTemplate.embeddedEditor !== 'undefined'
                      ? values.masterTemplate.embeddedEditor
                      : true
                  }
                  onChange={(e) => {
                    /**
                     * Keep track of previous body if the user switches between active and inactive editor
                     * Previous design should not be deleted while editing
                     */
                    let currentBody = this.formikProps.values.masterTemplate
                      ? this.formikProps.values.masterTemplate.body
                      : '';
                    this.formikProps.setFieldValue(
                      'masterTemplate[body]',
                      this.state.previousBody,
                    );
                    if (!e.target.checked) {
                      let jsonBody = this.formikProps.values.masterTemplate
                        ? this.formikProps.values.masterTemplate.jsonBody
                        : '';
                      this.formikProps.setFieldValue(
                        'masterTemplate[jsonBody]',
                        '',
                      );
                      this.setState({ jsonBody: jsonBody });
                    } else {
                      this.formikProps.setFieldValue(
                        'masterTemplate[jsonBody]',
                        typeof this.state.jsonBody === 'string'
                          ? this.state.jsonBody
                          : JSON.stringify(this.state.jsonBody),
                      );
                    }
                    this.setState({
                      previousBody: currentBody,
                      embeddedEditorSwitchTouched: true,
                    });
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  name="masterTemplate[embeddedEditor]"
                  color="primary"
                />
              }
              label={<Translate>Unlayer activated</Translate>}
            />
            {touched.combined && Boolean(errors.combined) ? (
              <FormHelperText error={true}>{errors.combined}</FormHelperText>
            ) : null}
          </CheckboxField>
        </Grid>
        <Grid xs={12}>
          {values &&
          values.masterTemplate &&
          !values.masterTemplate.embeddedEditor
            ? this.renderLayoutSelect(props)
            : null}
        </Grid>
        <Grid xs={12}>
          <TextField
            id={'masterTemplate[addresser]'}
            label={<Translate>Addresser</Translate>}
            name={'masterTemplate[addresser]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.addresser
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.addresser
                ? errors.masterTemplate.addresser
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.addresser &&
              Boolean(errors.masterTemplate.addresser)
            }
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            id={'masterTemplate[subject]'}
            label={<Translate>Subject</Translate>}
            name={'masterTemplate[subject]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.subject
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.subject
                ? errors.masterTemplate.subject
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.subject &&
              Boolean(errors.masterTemplate.subject)
            }
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            id={'masterTemplate[txtBody]'}
            label={<Translate>Body (text)</Translate>}
            name={'masterTemplate[txtBody]'}
            defaultValue={
              values && values.masterTemplate
                ? values.masterTemplate.txtBody
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.txtBody
                ? errors.masterTemplate.txtBody
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.txtBody &&
              Boolean(errors.masterTemplate.txtBody)
            }
            multiline
          />
        </Grid>
        <Grid xs={12}>{this.renderEditor(props)}</Grid>
      </>
    );
  };

  renderEditor = (props) => {
    const { seasonId, modules } = this.props;
    const { handleChange, handleBlur, values, touched, errors } = props;
    let embeddedEditorActive;

    if (!seasonId && !this.state.embeddedEditorSwitchTouched) {
      embeddedEditorActive = true;
    } else {
      embeddedEditorActive =
        values && values.masterTemplate && values.masterTemplate.embeddedEditor;
    }

    let blocks = Object.keys(modules).map((key) => {
      return typeof modules[key].blockDesign === 'string' ||
        modules[key].blockDesign instanceof String
        ? JSON.parse(modules[key].blockDesign)
        : modules[key].blockDesign;
    });

    return (
      <>
        <Box
          sx={
            !embeddedEditorActive ? { display: 'none' } : { display: 'block' }
          }
        >
          <EmailEditor
            ref={this.editor}
            options={merge(
              {},
              Config.base,
              { blocks: blocks },
              { mergeTagsConfig: { sort: false } } /*Config.layout*/,
            )}
            onReady={() => {
              this.onEmailEditorLoad(embeddedEditorActive);
            }}
            minHeight={'1000px'}
          />
        </Box>
        <Box
          sx={embeddedEditorActive ? { display: 'none' } : { display: 'block' }}
        >
          <TextField
            id={'masterTemplate[body]'}
            label={<Translate>Body (HTML)</Translate>}
            name={'masterTemplate[body]'}
            defaultValue={
              values && values.masterTemplate ? values.masterTemplate.body : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.body
                ? errors.masterTemplate.body
                : ''
            }
            error={
              touched.masterTemplate &&
              errors.masterTemplate &&
              touched.masterTemplate.body &&
              Boolean(errors.masterTemplate.body)
            }
            multiline
          />
        </Box>
      </>
    );
  };

  loadTemplate = (editorActive) => {
    const { channel, seasonId } = this.props;
    const { previousBody, templateLoaded } = this.state;

    if (
      !this.isEditorLoaded ||
      !this.isComponentMounted ||
      !this.editor.current.editor ||
      (seasonId && isEmpty(channel))
    ) {
      return;
    }

    if (templateLoaded) {
      return;
    }

    this.editor.current.editor.addEventListener('design:updated', () => {
      this.editor.current.editor.exportHtml((editorData) => {
        const { design, html } = editorData;
        this.formikProps.setFieldValue(
          'masterTemplate[jsonBody]',
          JSON.stringify(design),
          false,
        );
        this.formikProps.setFieldValue('masterTemplate[body]', html, false);
      });
    });

    this.editor.current.editor.addEventListener('design:loaded', () => {
      this.editor.current.editor.exportHtml((editorData) => {
        const { design, html } = editorData;
        this.setState({ jsonBody: JSON.stringify(design) });
        if (!editorActive && !previousBody) {
          this.setState({ previousBody: html });
        }
        if (
          this.formikProps.values &&
          this.formikProps.values.masterTemplate &&
          this.formikProps.values.masterTemplate.embeddedEditor
        ) {
          this.formikProps.setFieldValue(
            'masterTemplate[jsonBody]',
            JSON.stringify(design),
            false,
          );
          this.formikProps.setFieldValue('masterTemplate[body]', html, false);
        }
      });
    });

    this.editor.current.editor.registerCallback(
      'displayCondition',
      (data, done) => {
        this.setState({ conditionsDialog: true });
        this.done = done;
      },
    );

    this.setMergeTags();
    this.editor.current.editor.setLinkTypes(LinkTypes);

    if (
      !isEmpty(channel) &&
      channel.masterTemplate &&
      channel.masterTemplate.jsonBody &&
      channel.masterTemplate.body &&
      channel.masterTemplate.embeddedEditor
    ) {
      const design = JSON.parse(channel.masterTemplate.jsonBody);
      this.editor.current.editor.loadDesign(design);
      this.formikProps.setFieldValue(
        'masterTemplate[jsonBody]',
        channel.masterTemplate.jsonBody,
        false,
      );
    }

    this.setState({ templateLoaded: true });
  };

  setMergeTags = () => {
    const { mailingModules } = this.props;

    let newTags = {
      mailing_modules: {
        name: 'Bausteine',
        mergeTags: {},
      },
    };

    let mergeTags = {};

    Object.values(mailingModules).map((module) => {
      let tag = {};
      let tagString = module.tag ? ',"' + module.tag + '"' : ',' + module.tag;
      let affiliateString = module.affiliate
        ? ', customer.affiliate.interfaceId'
        : ',' + module.affiliate;

      tag['name'] = module.position;
      tag['value'] =
        '{{textmodule("' +
        module.position +
        '"' +
        tagString +
        affiliateString +
        ')}}';
      tag['sample'] = module.boilerplate ? module.boilerplate : '';

      mergeTags[module.position] = tag;
    });

    newTags.mailing_modules.mergeTags = Object.keys(mergeTags)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: mergeTags[key],
        }),
        {},
      );

    let finalTags = merge({}, Variables, newTags);

    this.editor.current.editor.setMergeTags(finalTags);
  };

  addDisplayCondition = (condition) => {
    this.done(condition);
    this.cancelDisplayCondition();
  };

  cancelDisplayCondition = () => {
    this.setState({ conditionsDialog: false });
  };

  onEmailEditorLoad = (editorActive) => {
    this.isEditorLoaded = true;
    setTimeout(() => this.loadTemplate(editorActive), 1);
  };

  renderMailingFieldset = () => {
    // const {values, handleChange, handleBlur, errors, touched, setFieldValue} = props;

    return <Grid container></Grid>;
  };

  renderLayoutSelect = (props) => {
    const { layouts } = this.props;
    const { values, handleChange, handleBlur, errors, touched } = props;

    if (isEmpty(layouts)) {
      return null;
    }

    return (
      <TextField
        select
        id={'masterTemplate[layout]'}
        label={<Translate>Layout</Translate>}
        name={'masterTemplate[layout]'}
        value={
          values && values.masterTemplate && values.masterTemplate.layout
            ? values.masterTemplate.layout
            : ''
        }
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={
          touched.masterTemplate &&
          errors.masterTemplate &&
          touched.masterTemplate.layout
            ? errors.masterTemplate.layout
            : ''
        }
        error={
          touched.masterTemplate &&
          errors.masterTemplate &&
          touched.masterTemplate.layout &&
          Boolean(errors.masterTemplate.layout)
        }
        fullWidth
      >
        {keys(layouts).map((layoutId) => {
          return (
            <MenuItem key={Math.random()} value={layouts[layoutId].id}>
              {' '}
              {layouts[layoutId].primaryText}{' '}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  renderEmailAccounts = (props) => {
    const { account } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (isEmpty(account)) {
      values.accountsAvailable
        ? props.setFieldValue('accountsAvailable', false)
        : null;
      return null;
    } else {
      !values.accountsAvailable
        ? props.setFieldValue('accountsAvailable', true)
        : null;
    }

    return (
      <Grid xs={12} sm={6}>
        <TextField
          select
          id={'account'}
          label={<Translate>email account</Translate>}
          name={'account'}
          value={values.account ? values.account : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.account && errors.account ? errors.account : ''}
          error={touched.account && touched.account && Boolean(errors.account)}
          fullWidth
        >
          <EmptyMenuItem key="emailAccountNull" />
          {account.map((currentAccount) => {
            return (
              <MenuItem key={Math.random()} value={currentAccount.id}>
                {currentAccount.primaryText}{' '}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  handleTagChange = (e, value, props) => {
    const { setFieldValue } = props;
    const { tags } = this.props;

    if (typeof value === 'string') {
      let foundTags = pickBy(tags, { massnId: value });
      if (Object.keys(foundTags).length === 1) {
        setFieldValue('tag', foundTags[Object.keys(foundTags)[0]]);
      } else {
        setFieldValue('tag', { massnId: value });
      }
    } else {
      setFieldValue('tag', value);
    }
  };

  renderAutocomplete = (props) => {
    const { tags } = this.props;
    const { handleBlur, values } = props;

    return (
      <Grid xs={12} sm={6}>
        <Autocomplete
          autoSelect
          onBlur={handleBlur}
          id="tag"
          fullWidth
          name="tag"
          options={tags ? Object.values(tags) : []}
          getOptionLabel={(tag) =>
            typeof tag === 'string'
              ? tag
              : tag && tag.primaryText
                ? tag.primaryText
                : tag && tag.massnId
                  ? tag.massnId
                  : tag && tag.id && tags[tag.id] && tags[tag.id].primaryText
                    ? tags[tag.id].primaryText
                    : ''
          }
          value={values.tag ? values.tag : null}
          freeSolo
          sx={{ display: 'inline-flex', backgroundColor: 'transparent' }}
          onChange={(e, value) => this.handleTagChange(e, value, props)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                mt: 0.5,
                mb: 0.25,
              }}
              error={false}
              label={<Translate>customer list tag</Translate>}
            />
          )}
        />
      </Grid>
    );
  };

  renderSelectionSelect = (props) => {
    const { selections, season } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (isEmpty(selections) || season.isParent) {
      return null;
    }

    return (
      <Grid xs={12} sm={6}>
        <TextField
          select
          id={'season[selection]'}
          label={<Translate>Selection</Translate>}
          name={'season[selection]'}
          value={
            values.season && values.season.selection
              ? values.season.selection
              : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.season && errors.season && touched.season.selection
              ? errors.season.selection
              : ''
          }
          error={
            touched.season &&
            errors.season &&
            touched.season.selection &&
            Boolean(errors.season.selection)
          }
          fullWidth
        >
          <EmptyMenuItem key="seasonSelectionNull" />
          {keys(selections).map((selectionId) => {
            return (
              <MenuItem key={Math.random()} value={selections[selectionId].id}>
                {' '}
                {selections[selectionId].title}{' '}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  renderSeasonSelect = (props) => {
    const { seasons, season } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    if (isEmpty(seasons) || season.isParent) {
      return null;
    }

    let renderSeasons = [];
    keys(seasons).map((seasonId) => {
      if (!seasons[seasonId].selection && seasons[seasonId].type === 'email') {
        renderSeasons.push(seasons[seasonId]);
      }
    });

    if (isEmpty(renderSeasons)) {
      return null;
    }

    return (
      <Grid xs={12} sm={6}>
        <TextField
          select
          id={'season[parent]'}
          label={<Translate>Parent</Translate>}
          name={'season[parent]'}
          value={
            values.season && values.season.parent ? values.season.parent : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.season && errors.season && touched.season.parent
              ? errors.season.parent
              : ''
          }
          error={
            touched.season &&
            errors.season &&
            touched.season.parent &&
            Boolean(errors.season.parent)
          }
        >
          <EmptyMenuItem key="seasonNull" />
          {renderSeasons.map((season) => {
            return (
              <MenuItem key={Math.random()} value={season.id}>
                {' '}
                {season.primaryText}{' '}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  renderFieldset = (props) => {
    const { seasonType, campaign } = this.props;
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } =
      props;
    let typeSpecific;
    this.formikProps = props;

    switch (seasonType) {
      case 'email':
        typeSpecific = this.renderEmailFieldset;
        break;
      case 'mailing':
        typeSpecific = this.renderMailingFieldset;
        break;
    }

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Input
          type="hidden"
          name="season[id]"
          defaultValue={
            values.season && values.season.id ? values.season.id : ''
          }
        />
        <Input
          type="hidden"
          name="season[campaign]"
          defaultValue={
            values.season && values.season.campaign
              ? values.season.campaign
              : ''
          }
        />
        <Input
          type="hidden"
          name="season[status]"
          defaultValue={
            values.season && values.season.status
              ? values.season.status
              : 'approved'
          }
        />
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>General</Translate>
            </Typography>
          </Grid>
          {this.renderSeasonSelect(props)}
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Config key</Translate>}
              defaultValue={
                values.season && values.season.configKey
                  ? values.season.configKey
                  : ''
              }
              name={'season[configKey]'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.season && errors.season && touched.season.configKey
                  ? errors.season.configKey
                  : ''
              }
              error={
                touched.season &&
                errors.season &&
                touched.season.configKey &&
                Boolean(errors.season.configKey)
              }
            />
          </Grid>
          {!campaign.isChild && (
            <Grid xs={12} sm={6}>
              <TextField
                label={<Translate>campaign key</Translate>}
                defaultValue={
                  values.campaign && values.campaign.campaignKey
                    ? values.campaign.campaignKey
                    : ''
                }
                name={'campaign[campaignKey]'}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  touched.campaign &&
                  errors.campaign &&
                  touched.campaign.campaignKey
                    ? errors.campaign.campaignKey
                    : ''
                }
                error={
                  touched.campaign &&
                  errors.campaign &&
                  touched.campaign.campaignKey &&
                  Boolean(errors.campaign.campaignKey)
                }
              />
            </Grid>
          )}
          {this.renderEmailAccounts(props)}
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Tracking</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="season[tracking]"
              label={<Translate>Campaign Tracking</Translate>}
              checked={props.values?.season?.tracking ?? false}
              setFieldValue={setFieldValue}
              error={
                props.touched?.season?.tracking &&
                props.errors?.season?.tracking
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="personalizedTracking"
              label={<Translate>Personalized Tracking</Translate>}
              checked={props.values?.personalizedTracking ?? false}
              setFieldValue={setFieldValue}
              error={
                props.touched?.personalizedTracking &&
                props.errors?.personalizedTracking
              }
            />
          </Grid>
          {typeSpecific(props)}
        </Grid>
      </>
    );
  };

  renderForm = () => {
    const {
      season,
      channel,
      loading,
      onCancel,
      seasonType,
      onMount,
      onSubmit,
      onValidityChange,
      seasonId,
      campaignId,
      campaign,
      token,
      form,
      theme,
    } = this.props;
    let { ...defaultValues } = this.props.values;
    const { modulesLoaded, emailTagsLoading } = this.state;

    if (isEmpty(form) && !seasonId) {
      // if seasonId is missing form should not be empty
      return null;
    }

    if (seasonId && isEmpty(season)) {
      //if seasonId is set season should not be empty
      return null;
    }

    if (isEmpty(campaign) || !campaignId) {
      return null;
    }

    if (
      seasonId &&
      (isEmpty(channel) ||
        (seasonType === 'email' && !('masterTemplate' in channel)))
    ) {
      return null;
    }

    if (!modulesLoaded || emailTagsLoading) {
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    let seasonTypeString = '';
    switch (seasonType) {
      case 'email':
        seasonTypeString = 'New email';
        break;
      case 'mailing':
        seasonTypeString = 'New mailing';
        break;
    }

    let headline = season.primaryText ? (
      season.primaryText
    ) : (
      <Translate>{seasonTypeString}</Translate>
    );
    let emailChannel, initialValues, printPeriod, approvalPeriod;

    if (seasonId) {
      if (season.cycle === 'singular') {
        if (channel.printPeriod !== null) {
          printPeriod = new Intl.DateTimeFormat(theme.locale.de).format(
            new Date(channel.printPeriod * 1000),
          );
        }

        if (channel.approvalPeriod !== null) {
          approvalPeriod = new Intl.DateTimeFormat(theme.locale.de).format(
            new Date(channel.approvalPeriod * 1000),
          );
        }
      } else {
        printPeriod = channel.printPeriod;
        approvalPeriod = channel.approvalPeriod;
      }

      let channelCopy = merge(Object.assign({}, channel), {
        approvalPeriod: approvalPeriod,
        printPeriod: printPeriod,
      });
      emailChannel =
        channelCopy.masterTemplate && channelCopy.masterTemplate.layout
          ? merge(channelCopy, {
              masterTemplate: { layout: channel.masterTemplate.layout.id },
            })
          : channelCopy;
      delete emailChannel.season;
      initialValues =
        !isEmpty(season) && !isEmpty(emailChannel)
          ? Object.assign(
              { season: season, campaign: campaign, accountsAvailable: false },
              emailChannel,
            )
          : Object.assign(defaultValues, { accountsAvailable: false });
    } else {
      initialValues = Object.assign(defaultValues, {
        campaign: {
          campaignKey: campaign.campaignKey,
          title: campaign.title,
          description: campaign.description,
          id: campaign.id,
        },
        masterTemplate: { embeddedEditor: true },
        season: { campaign: campaign.id, id: null, cycle: 'monthly' },
        accountsAvailable: false,
      });
    }

    initialValues = {
      ...initialValues,
      campaign: {
        ...campaign,
        campaignKey: campaign.isChild ? null : campaign.campaignKey,
      },
    };

    return (
      <Form
        validationSchema={validationSchema}
        onCancel={onCancel}
        headline={headline}
        onMount={onMount}
        initialValues={initialValues}
        values={season}
        onSubmit={onSubmit}
        onValidityChange={onValidityChange}
        name={seasonType}
        loading={!isEmpty(loading)}
        renderFieldset={this.renderFieldset}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderForm()}
        {this.state.conditionsDialog ? (
          <DisplayConditionDialog
            addDisplayCondition={this.addDisplayCondition}
            cancelDisplayCondition={this.cancelDisplayCondition}
          />
        ) : null}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getSeasonById = makeSeasonById();
  const getSelectableSelections = makeSelectableSelections();
  const getTextModules = makeTextModulesBySeasonIdForMergeTags();

  return (state, props) => {
    const {
      entities: {
        emailModule,
        layout,
        tag,
        season: seasons,
        email: emailChannel,
        mailing: mailingChannel,
        campaign,
      },
      forms: { mailing, email, season: tokenForm },
      resultsets: { emailModule: resultset },
      loading: loading,
    } = state;

    const seasonId = props?.match?.params?.seasonId
      ? parseInt(props.match.params.seasonId)
      : null;
    const season = getSeasonById(state, props);
    const selections = getSelectableSelections(state, props);
    const textModules = getTextModules(state, props);

    let channel,
      form = null;

    switch (props.seasonType) {
      case 'email':
        form = email ? email : null;
        channel =
          emailChannel && season && season.email && emailChannel[season.email]
            ? emailChannel[season.email]
            : null;
        break;
      case 'mailing':
        form = mailing ? mailing : null;
        channel =
          mailingChannel &&
          season &&
          season.mailing &&
          mailingChannel[season.mailing]
            ? mailingChannel[season.mailing]
            : null;
        break;
    }

    return {
      seasonId: seasonId,
      mailingModules: textModules,
      modules: !isEmpty(emailModule) ? pick(emailModule, resultset) : {},
      campaign: campaign[props.campaignId],
      seasons: seasons ? seasons : {},
      season: season ? season : {},
      selections: selections,
      tags: tag ? tag : {},
      account: selectAllEmailAccounts(state),
      layouts: layout,
      channel: channel,
      token: !isEmpty(tokenForm) ? tokenForm.updateToken : '',
      form: tokenForm,
      loading: loading,
    };
  };
};

const enhance = compose(
  withTheme,
  withRouter,
  connect(makeMapStateToProps, {
    loadMailingModulesByMode,
    initEmailForm,
    loadSeason,
    loadSeasons,
    fetchAllEmailAccounts,
    loadSelections,
    outdateEntity,
    resetEntity,
    loadLayouts,
    loadEmailBySeason,
    loadEmailTags,
    loadList,
  }),
);

export default enhance(SeasonEdit);
