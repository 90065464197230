import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, findKey, merge } from 'lodash';
import Form from '../../../components/Form';
import Translate from '../../../components/service/Translate';
import { Input, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { initForm } from '../actions';
import ValidationErrors from '../../../ValidationErrors';
import * as Yup from 'yup';
import Grid from '@mui/material/Unstable_Grid2';

let validationSchema = Yup.object().shape({
  setting: Yup.object()
    .shape({
      id: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
      setting: Yup.string().required(ValidationErrors.required),
    })
    .required(ValidationErrors.required),
  value: Yup.string(),
});

class Edit extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    setting: {},
    values: {},
    style: {},
  };

  state = {
    key: false,
    children: [],
  };

  componentDidMount = () => {
    if (this.props.type === 'adding') {
      this.props.initForm();
    }
  };

  getSettingId = (event, value, setFieldValue) => {
    let setting = value;
    let settingId = findKey(this.props.setting, (s) => {
      return s.primaryText === value;
    });

    setFieldValue('setting[id]', settingId);
    setFieldValue('setting[setting]', setting);
  };

  renderFieldset = (props) => {
    const { affiliateSettingId, type, setting } = this.props;
    const { values, handleChange, handleBlur, setFieldValue, touched, errors } =
      props;

    const dataSource = Object.values(setting);

    return (
      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <Autocomplete
            freeSolo
            id="setting"
            name="setting"
            size="small"
            options={dataSource}
            getOptionLabel={(selected) => {
              if ('object' === typeof selected) {
                return selected.primaryText;
              }

              return setting[selected] ? setting[selected].primaryText : '';
            }}
            onInputChange={(event, value) =>
              this.getSettingId(event, value, setFieldValue)
            }
            value={values.setting ? values.setting.id : null}
            disabled={type === 'editing'}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                variant="standard"
                label={<Translate>Schlüssel</Translate>}
                name="setting"
                helperText={
                  touched.setting && Boolean(errors.setting)
                    ? errors.setting.setting
                    : ''
                }
                error={touched.setting && Boolean(errors.setting)}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Input
            type="hidden"
            name="updateToken"
            defaultValue={values.updateToken ? values.updateToken : ''}
          />
          <TextField
            fullWidth
            multiline
            id="value"
            name="value"
            label={<Translate>Einstellung</Translate>}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={
              affiliateSettingId && values.value ? values.value : ''
            }
            helperText={touched.value ? errors.value : ''}
            error={touched.value && Boolean(errors.value)}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      affiliateId,
      affiliateSetting,
      setting,
      affiliateSettingId,
      OnMount,
      onCancel,
      form,
      token,
      onSubmit,
    } = this.props;
    const { ...defaultValues } = this.props.values;
    let initialValues;

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    defaultValues.affiliate = affiliateId;

    initialValues =
      affiliateSetting && affiliateSettingId
        ? merge({}, affiliateSetting[affiliateSettingId], {
            setting: { id: affiliateSetting[affiliateSettingId].setting },
          })
        : defaultValues;

    return (
      <Form
        onMount={OnMount}
        name="affiliateSetting"
        onSubmit={onSubmit}
        onCancel={onCancel}
        values={isEmpty(setting) ? {} : setting}
        validationSchema={validationSchema}
        initialValues={initialValues}
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

const makeMapStateToProps = () => {
  // eslint-disable-next-line no-unused-vars
  return (state, props) => {
    const {
      entities: { affiliatesetting: affiliatesetting },
      forms: { affiliatesetting: form },
    } = state;

    if (!form) {
      return {};
    }

    return {
      affiliateSetting: affiliatesetting,
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
    };
  };
};

export default connect(makeMapStateToProps, { initForm })(Edit);
