import React, { Component } from 'react';
import { TextField, MenuItem, Input, Typography } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { isEqual, isEmpty, values } from 'lodash';
import * as Yup from 'yup';
import Grid from '@mui/material/Unstable_Grid2';
import { ErrorMessage, FormikProps, FormikValues } from 'formik';
import Translate from '../../../components/service/Translate';
import Upload from '../../../components/form/Upload';
import Tags from '../../Affiliate/components/Tags';
import Form from '../../../components/Form';
import ValidationErrors from '../../../ValidationErrors';
import ValidationRules from '../../../ValidationRules';
import { EmptyMenuItem } from '../../../components/StyledElements/StyledElements';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import { withRouter, WithRouterProps } from '../../../withRouter';
import { RootState } from '../../../reducers';
import { Season } from '../../Season/SeasonType';
import {
  fetchMailingModuleById,
  selectMailingModuleById,
  fetchToken,
  removeUpdateToken,
} from '../MailingModuleSlice';
import { fetchAllLocations } from '../../Location/LocationSlice';
import { fetchAllSeasons } from '../../Season/SeasonSlice';
import { fetchAllAffiliates } from '../../Affiliate/AffiliateSlice';
import { MailingModule } from '../MailingModuleType';
import {
  makeHeadLocations,
  makeSelectableAffiliates,
} from '../../Location/selectors';
import makeSeasonsByPrintable from '../../Season/selectors';
import { Location } from '../../Location/LocationType';
import { Affiliate } from '../../Affiliate/AffiliateType';

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ValidationErrors.required),
  position: Yup.string()
    .matches(ValidationRules.configKeyRegex, {
      message: ValidationErrors.configKeyRegexMessage,
    })
    .required(ValidationErrors.configKeyRegexMessage),
  boilerplate: Yup.string().nullable(),
  affiliate: Yup.string().nullable(),
  location: Yup.string().nullable(),
  tag: Yup.string().nullable(),
  page: Yup.string()
    .nullable()
    .when('pageRequired', {
      is: true,
      then: () => Yup.string().required(ValidationErrors.requiredSelect),
      otherwise: () => Yup.string().nullable(),
    }),
  width: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  height: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  x: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  y: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  offsetX: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  offsetY: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  orientate: Yup.string().nullable(),
  rotate: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  font: Yup.string().nullable(),
  fontsize: Yup.number().nullable().typeError(ValidationErrors.typeErrorNumber),
  fontColor: Yup.string().nullable().matches(ValidationRules.cmykRegex, {
    message: ValidationErrors.cmykRegexMessage,
  }),
  alignment: Yup.string().nullable(),
  shape: Yup.string().nullable(),
  borderStyle: Yup.string().nullable(),
  borderWidth: Yup.number()
    .nullable()
    .typeError(ValidationErrors.typeErrorNumber),
  borderColor: Yup.string().nullable().matches(ValidationRules.cmykRegex, {
    message: ValidationErrors.cmykRegexMessage,
  }),
  fillColor: Yup.string().nullable().matches(ValidationRules.cmykRegex, {
    message: ValidationErrors.cmykRegexMessage,
  }),
  mode: Yup.string().nullable(),
  templateMode: Yup.string().nullable(),
  embedded: Yup.bool(),
  season: Yup.string().nullable(),
});

type EntryType = {
  id: string;
  primaryText: string;
  type?: string;
};

type EditProps = {
  seasons?: (Season | undefined)[];
  moduleId: number | null;
  module?: MailingModule | null | undefined;
  onSubmit: (formData: FormData) => Promise<void>;
  onCancel: () => void;
  values?: {
    fontsize: number;
    height: string;
    templateMode: string;
    mode: string;
    borderWidth: string;
    offsetX: string;
    offsetY: string;
    y: string;
    x: string;
    width: string;
    rotate: string;
    template: string | null;
    alignment: string;
    embedded: boolean;
    updateToken: string | null;
  };
  mode?: EntryType[] | null;
  fonts?: EntryType[];
  alignments?: EntryType[];
  orientations?: EntryType[];
  shapes?: EntryType[];
  templateModes?: EntryType[];
  borderStyles?: EntryType[];
  pages?: EntryType[];
};

type EditState = {
  affiliatesEnabled: boolean;
  embedded: boolean;
  standAlone: boolean;
  dataLoading: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EditProps & WithRouterProps;

class Edit extends Component<Props, EditState> {
  static defaultProps = {
    seasons: [],
    moduleId: null,
    values: {
      fontsize: 8,
      height: '0',
      templateMode: 'embedded',
      mode: 'text',
      borderWidth: '0',
      offsetX: '0',
      offsetY: '0',
      y: '0',
      x: '0',
      width: '0',
      rotate: '0',
      template: null,
      alignment: 'left',
      embedded: true,
      updateToken: null,
    },
    mode: [
      { id: 'text', primaryText: 'text' },
      { id: 'qr_code', primaryText: 'qr_code' },
      { id: 'object', primaryText: 'object' },
    ],
    fonts: [
      { id: 'regular', primaryText: 'regular' },
      { id: 'bold', primaryText: 'bold' },
      { id: 'demi', primaryText: 'demi' },
      { id: 'light', primaryText: 'light' },
      { id: 'headline', primaryText: 'headline' },
      { id: 'custom', primaryText: 'custom' },
    ],
    alignments: [
      { id: 'left', primaryText: 'left' },
      { id: 'center', primaryText: 'center' },
      { id: 'right', primaryText: 'right' },
    ],
    orientations: [
      { id: 'north', primaryText: 'north' },
      { id: 'east', primaryText: 'east' },
      { id: 'south', primaryText: 'south' },
      { id: 'west', primaryText: 'west' },
    ],
    shapes: [
      { id: 'rectangle', primaryText: 'rectangle' },
      { id: 'circle', primaryText: 'circle' },
    ],
    templateModes: [
      { id: 'embedded', primaryText: 'embedded' },
      { id: 'positionable', primaryText: 'positionable' },
      { id: 'autonomous', primaryText: 'autonomous' },
      { id: 'flipside', primaryText: 'flip side' },
    ],
    borderStyles: [
      { id: 'dasharray=none', primaryText: 'solid' },
      { id: 'dasharray={3 3}', primaryText: 'dashed' },
      { id: 'dasharray={0.5 3}', primaryText: 'dotted' },
    ],
    pages: [
      { id: 'default', primaryText: 'pageDefault' },
      { id: 'page2', primaryText: 'pagePage2' },
      { id: 'page3', primaryText: 'pagePage3' },
      { id: 'page4', primaryText: 'pagePage4' },
      { id: 'back', primaryText: 'pageBack' },
      { id: 'outside', primaryText: 'pageOutside' },
    ],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      affiliatesEnabled: false,
      embedded: true,
      standAlone: false,
      dataLoading: true,
    };
  }

  componentDidMount() {
    const {
      inMbvdMode,
      moduleId,
      fetchMailingModuleById,
      fetchToken,
      fetchAllSeasons,
      fetchAllLocations,
      fetchAllAffiliates,
      values,
    } = this.props;
    const promises = [];

    window.scrollTo(0, 0);
    this.setState({ affiliatesEnabled: inMbvdMode, dataLoading: true });

    if (moduleId) {
      promises.push(fetchMailingModuleById(moduleId));
    } else {
      promises.push(fetchToken());
    }

    promises.push(fetchAllSeasons());
    promises.push(fetchAllLocations({ columns: ['id'], sort: ['asc'] }));

    if (inMbvdMode) {
      promises.push(fetchAllAffiliates());
    }

    Promise.all(promises).then(() => {
      this.setState({ dataLoading: false });
    });

    this.setState({
      embedded: values && 'embedded' in values ? values.embedded : true,
    });
    this.setState({
      standAlone:
        values && 'templateMode' in values
          ? ['autonomous', 'flipside'].indexOf(values.templateMode) !== -1
          : false,
    });
  }

  shouldComponentUpdate(nextProps: Props, nextState: EditState) {
    const { values, moduleId, module, seasons, locations, affiliates, token } =
      this.props;
    const { embedded, dataLoading, standAlone } = this.state;

    return (
      !isEqual(nextProps.values, values) ||
      !isEqual(nextProps.moduleId, moduleId) ||
      !isEqual(nextProps.module, module) ||
      !isEqual(nextProps.seasons, seasons) ||
      !isEqual(nextProps.locations, locations) ||
      !isEqual(nextProps.affiliates, affiliates) ||
      !isEqual(nextState.embedded, embedded) ||
      !isEqual(nextState.dataLoading, dataLoading) ||
      !isEqual(nextState.standAlone, standAlone) ||
      !isEqual(nextProps.token, token)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { moduleId, fetchMailingModuleById, values } = this.props;

    if (moduleId !== prevProps.moduleId) {
      fetchMailingModuleById(moduleId);
    }

    if (!isEqual(values!.embedded, prevProps.values!.embedded)) {
      this.setState({ embedded: values!.embedded });
    }

    if (!isEqual(values!.templateMode, prevProps.values!.templateMode)) {
      this.setState({
        standAlone:
          ['autonomous', 'flipside'].indexOf(values!.templateMode) !== -1,
      });
    }
  }

  componentWillUnmount() {
    const { removeUpdateToken, token } = this.props;

    if (token) {
      removeUpdateToken();
    }
  }

  onTemplateModeChange = (value: string, props: FormikProps<FormikValues>) => {
    const { embedded } = this.state;
    const standAloneState = ['autonomous', 'flipside'].indexOf(value) !== -1;

    this.setState({ standAlone: standAloneState });
    props.setFieldValue('pageRequired', !embedded || standAloneState);
  };

  renderSeasonSelect = (season: Season | undefined) => {
    if (!season) {
      return null;
    }

    return (
      <MenuItem key={`season-${season.id}`} value={season.id}>
        {season.primaryText} ({season.type})
      </MenuItem>
    );
  };

  renderSelect = (item: EntryType | Location | Affiliate | undefined) => {
    if (!item) {
      return null;
    }

    return (
      <MenuItem key={item.id} value={item.id}>
        <Translate>{item.primaryText}</Translate>
      </MenuItem>
    );
  };

  renderLocation = (props: FormikProps<FormikValues>) => {
    const { locations, affiliates } = this.props;
    const { affiliatesEnabled } = this.state;
    const { values, handleChange, touched, errors } = props;

    if (affiliatesEnabled) {
      return (
        <Grid xs={12} sm={6}>
          <TextField
            select
            id="affiliate"
            name="affiliate"
            label={
              <Translate>
                Affiliate (used for every affiliate if blank)
              </Translate>
            }
            value={values.affiliate ?? ''}
            onChange={handleChange('affiliate')}
            helperText={<ErrorMessage name="affiliate" />}
            error={touched.affiliate && Boolean(errors.affiliate)}
          >
            <EmptyMenuItem key="affiliateNull" value="" />
            {affiliates.map(this.renderSelect)}
          </TextField>
          <Tags
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
          />
        </Grid>
      );
    }
    return (
      <>
        <Grid xs={12} sm={6}>
          <TextField
            select
            id="location"
            name="location"
            label={
              <Translate>Location (used for every location if blank)</Translate>
            }
            value={values.location ?? ''}
            onChange={handleChange('location')}
            helperText={<ErrorMessage name="location" />}
            error={touched.location && Boolean(errors.location)}
          >
            <EmptyMenuItem key="locationNull" />
            {locations.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <Tags
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
      </>
    );
  };

  renderPosition = (props: FormikProps<FormikValues>) => {
    const { pages, orientations } = this.props;
    const { values, handleChange, handleBlur, errors, touched } = props;

    return (
      <>
        <Grid xs={12}>
          <TextField
            select
            name="page"
            label={<Translate>Page</Translate>}
            value={values.page ?? ''}
            onChange={handleChange('page')}
            helperText={<ErrorMessage name="page" />}
            error={touched.page && Boolean(errors.page)}
          >
            <EmptyMenuItem key="mailingModuleMailingNull" value="" />

            {pages!.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Width</Translate>}
            name="width"
            defaultValue={values.width}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="width" />}
            error={touched.width && Boolean(errors.width)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Height</Translate>}
            name="height"
            defaultValue={values.height}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="height" />}
            error={touched.height && Boolean(errors.height)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>X</Translate>}
            name="x"
            defaultValue={values.x}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="x" />}
            error={touched.x && Boolean(errors.x)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Y</Translate>}
            name="y"
            defaultValue={values.y}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="y" />}
            error={touched.y && Boolean(errors.y)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Offset X</Translate>}
            name="offsetX"
            defaultValue={values.offsetX}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="offsetX" />}
            error={touched.offsetX && Boolean(errors.offsetX)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Offset Y</Translate>}
            name="offsetY"
            defaultValue={values.offsetY}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="offsetY" />}
            error={touched.offsetY && Boolean(errors.offsetY)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="orientate"
            label={<Translate>Orientation</Translate>}
            value={values.orientate ?? ''}
            onChange={handleChange('orientate')}
            helperText={<ErrorMessage name="orientate" />}
            error={touched.orientate && Boolean(errors.orientate)}
          >
            <EmptyMenuItem key="fontNull" value="" />
            {orientations!.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Rotation</Translate>}
            name="rotate"
            defaultValue={values.rotate}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="rotate" />}
            error={touched.rotate && Boolean(errors.rotate)}
          />
        </Grid>
      </>
    );
  };

  renderFont = (props: FormikProps<FormikValues>) => {
    const { fonts, alignments } = this.props;
    const { values, handleChange, handleBlur, errors, touched } = props;
    const { embedded } = props.values;

    if (embedded) {
      return null;
    }

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Font</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="font"
            label={<Translate>Font</Translate>}
            value={values.font ?? ''}
            onChange={handleChange('font')}
            helperText={<ErrorMessage name="font" />}
            error={touched.font && Boolean(errors.font)}
          >
            <EmptyMenuItem key="fontNull" />
            {fonts!.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Size</Translate>}
            name="fontsize"
            defaultValue={values.fontsize}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="fontsize" />}
            error={touched.fontsize && Boolean(errors.fontsize)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Color (cmyk)</Translate>}
            name="fontColor"
            defaultValue={values.fontColor ? values.fontColor : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="fontcolor" />}
            error={touched.fontColor && Boolean(errors.fontColor)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="alignment"
            label={<Translate>Alignment</Translate>}
            value={values.alignment ?? ''}
            onChange={handleChange('alignment')}
            helperText={<ErrorMessage name="alignment" />}
            error={touched.alignment && Boolean(errors.alignment)}
          >
            <EmptyMenuItem key="fontNull" />
            {alignments!.map(this.renderSelect)}
          </TextField>
        </Grid>
      </>
    );
  };

  renderBorder = (props: FormikProps<FormikValues>) => {
    const { borderStyles, shapes } = this.props;
    const { values, handleChange, handleBlur, errors, touched } = props;
    const { embedded } = props.values;

    if (embedded) {
      return null;
    }

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Border</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="shape"
            label={<Translate>Shape</Translate>}
            value={values.shape ?? ''}
            onChange={handleChange('shape')}
            helperText={<ErrorMessage name="shape" />}
            error={touched.shape && Boolean(errors.shape)}
          >
            <EmptyMenuItem key="shapeNull" />
            {shapes!.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="borderStyle"
            label={<Translate>Style</Translate>}
            value={values.borderStyle ?? ''}
            onChange={handleChange('borderStyle')}
            helperText={<ErrorMessage name="borderStyle" />}
            error={touched.borderStyle && Boolean(errors.borderStyle)}
          >
            <EmptyMenuItem key="borderNull" />
            {borderStyles!.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Width</Translate>}
            name="borderWidth"
            defaultValue={values.borderWidth}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="borderWidth" />}
            error={touched.borderWidth && Boolean(errors.borderWidth)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Color (cmyk)</Translate>}
            name="borderColor"
            defaultValue={values.borderColor ? values.borderColor : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="borderColor" />}
            error={touched.borderColor && Boolean(errors.borderColor)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Background color (cmyk)</Translate>}
            name="fillColor"
            defaultValue={values.fillColor ? values.fillColor : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="fillColor" />}
            error={touched.fillColor && Boolean(errors.fillColor)}
          />
        </Grid>
      </>
    );
  };

  renderFieldset = (props: FormikProps<FormikValues>) => {
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } =
      props;
    const { seasons, templateModes, mode } = this.props;

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              select
              label={<Translate>Module mode</Translate>}
              name="mode"
              value={values.mode ?? ''}
              onChange={handleChange('mode')}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="mode" />}
              error={touched.mode && Boolean(errors.mode)}
            >
              {mode!.map(this.renderSelect)}
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              name="title"
              defaultValue={values.title ? values.title : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="title" />}
              error={touched.title && Boolean(errors.title)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Config key</Translate>}
              name="position"
              defaultValue={values.position ? values.position : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="position" />}
              error={touched.position && Boolean(errors.position)}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Text</Translate>}
              name="boilerplate"
              defaultValue={values.boilerplate ? values.boilerplate : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="boilerplate" />}
              error={touched.boilerplate && Boolean(errors.boilerplate)}
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Upload</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Upload
              label={
                <Translate wildcards={{ '%type%': 'Vorlage pdf-x3' }}>
                  Upload file %type%
                </Translate>
              }
              name="fileTemplate"
              setFieldValue={setFieldValue}
            />
            <Input
              type="hidden"
              id="template"
              name="template"
              value={values.template ?? ''}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              name="templateMode"
              label={<Translate>Template mode</Translate>}
              value={values.templateMode ?? ''}
              onChange={(event) => {
                this.onTemplateModeChange(event.target.value, props);
                const promise = Promise.resolve(handleChange(event));
                promise.then(() => {
                  props.setFieldTouched('page', true, false);
                  props.validateForm();
                });
              }}
              helperText={<ErrorMessage name="templateMode" />}
              error={touched.templateMode && Boolean(errors.templateMode)}
            >
              <EmptyMenuItem key="mailingModuleTemplateModeNull" />
              {templateModes!.map(this.renderSelect)}
            </TextField>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Size & position</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="embedded"
              label={<Translate>Embedded?</Translate>}
              checked={values?.embedded ?? false}
              setFieldValue={setFieldValue}
              error={touched?.embedded && errors?.embedded}
            />
          </Grid>
          {this.renderPosition(props)}
          {this.renderFont(props)}
          {this.renderBorder(props)}
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Kampagne</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              name="season"
              label={<Translate>Kampagne</Translate>}
              value={values.season ?? ''}
              onChange={handleChange('season')}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="season" />}
              error={touched.season && Boolean(errors.season)}
            >
              <EmptyMenuItem key="mailingModuleMailingNull" value="" />
              {seasons.map(this.renderSeasonSelect)}
            </TextField>
          </Grid>
          {this.renderLocation(props)}
        </Grid>
      </>
    );
  };

  render() {
    const { module, token, onSubmit, onCancel, moduleId } = this.props;
    const {
      values: { ...defaultValues },
    } = this.props;
    const { dataLoading } = this.state;

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if (moduleId && isEmpty(module)) {
      // if moduleId is set module should not be empty
      return null;
    }

    if (!moduleId && !token) {
      // if moduleId is missing form should not be empty
      return null;
    }

    if (!moduleId && token) {
      // if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    const headline = module?.primaryText ?? <Translate>New module</Translate>;

    return (
      <Form
        headline={headline}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        initialValues={!isEmpty(module) ? module : defaultValues}
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

const mapStateToProps = () => {
  const getSeasonsByPrintable = makeSeasonsByPrintable();
  const getHeadLocations = makeHeadLocations();
  const getSelectableAffiliates = makeSelectableAffiliates();

  return (state: RootState, props: EditProps & WithRouterProps) => {
    const {
      entities: {
        config: { config },
        owner,
      },
      mailingModule: { updateToken: token },
      success,
    } = state;

    return {
      inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
      module: props.moduleId
        ? selectMailingModuleById(state, props.moduleId)
        : null,
      seasons: getSeasonsByPrintable(state),
      locations: values(getHeadLocations(state)),
      affiliates: values(getSelectableAffiliates(state)),
      token,
      success,
    };
  };
};

const connector = connect(mapStateToProps, {
  fetchAllSeasons,
  fetchAllLocations,
  fetchAllAffiliates,
  fetchMailingModuleById,
  fetchToken,
  removeUpdateToken,
});

export default withRouter(connector(Edit));
