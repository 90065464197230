import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual, find, values } from 'lodash';
import {
  Input,
  MenuItem,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentRemove from '@mui/icons-material/Remove';
import Translate from '../../../components/service/Translate';
import Tags from '../../Affiliate/components/Tags';
import { makeMailingsByPrintable } from '../../Mailing/selectors';
import {
  makeHeadLocations,
  makeSelectableAffiliates,
} from '../../Location/selectors_deprecated';
import Grid from '@mui/material/Unstable_Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';

class Signature extends Component {
  static propTypes = {
    signeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    signatureId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    theme: PropTypes.object,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    mailings: PropTypes.array,
    locations: PropTypes.array,
    affiliates: PropTypes.array,
    key: PropTypes.string,
    values: PropTypes.object,
    submitCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    formikProps: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  state = {
    expanded: false,
    affiliatesEnabled: false,
    currentMailing: null,
    currentBase: null,
  };

  componentDidMount = () => {
    const { inMbvdMode } = this.props;

    this.setState({ affiliatesEnabled: inMbvdMode });

    if (inMbvdMode) {
      this.setState({
        currentMailing: find(this.props.mailings, {
          id: this.props.values.mailing,
        }),
        currentBase: find(this.props.affiliates, {
          id: this.props.values.affiliate,
        }),
      });
    } else {
      this.setState({
        currentMailing: find(this.props.mailings, {
          id: this.props.values.mailing,
        }),
        currentBase: find(this.props.locations, {
          id: this.props.values.location,
        }),
      });
    }

    this.props.formikProps.setFieldValue(
      `mailingSignatures[${this.props.index}]`,
      this.props.values,
    );
  };

  componentDidUpdate(prevProps) {
    if (
      (prevProps.values &&
        this.props.values &&
        !isEqual(prevProps.values.mailing, this.props.values.mailing)) ||
      !isEqual(this.props.mailings, prevProps.mailings)
    ) {
      const { affiliatesEnabled } = this.state;

      if (affiliatesEnabled) {
        this.setState({
          currentMailing: find(this.props.mailings, {
            id: this.props.values.mailing,
          }),
          currentBase: find(this.props.affiliates, {
            id: this.props.values.affiliate,
          }),
        });
      } else {
        this.setState({
          currentMailing: find(this.props.mailings, {
            id: this.props.values.mailing,
          }),
          currentBase: find(this.props.locations, {
            id: this.props.values.location,
          }),
        });
      }
    }
    if (
      !isEqual(this.props.submitCount, prevProps.submitCount) &&
      this.props.errors &&
      this.props.errors.mailingSignatures &&
      this.props.errors.mailingSignatures[this.props.index]
    ) {
      this.setState({ expanded: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.signeeId, nextProps.signeeId) ||
      !isEqual(this.props.formikProps, nextProps.formikProps) ||
      !isEqual(this.props.mailings, nextProps.mailings) ||
      !isEqual(this.props.locations, nextProps.locations) ||
      !isEqual(this.props.affiliates, nextProps.affiliates) ||
      !isEqual(nextState, this.state)
    );
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  handleRemove = () => {
    this.props.onRemove(this.props.index);
    let signatures = JSON.parse(
      JSON.stringify(this.props.formikProps.values.mailingSignatures),
    );
    signatures.splice(this.props.index, 1);
    this.props.formikProps.setFieldValue('mailingSignatures', signatures);
  };

  handleMailingChange = (event, key) => {
    this.setState({ currentMailing: find(this.props.mailings, { id: key }) });
  };

  handleBaseChange = (event, key) => {
    const { affiliatesEnabled } = this.state;

    if (affiliatesEnabled) {
      this.setState({ currentBase: find(this.props.affiliates, { id: key }) });
    } else {
      this.setState({ currentBase: find(this.props.locations, { id: key }) });
    }
  };

  renderSelect = (item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {' '}
        {item.primaryText}{' '}
      </MenuItem>
    );
  };

  renderLocation = (values) => {
    const {
      index,
      locations,
      affiliates,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue,
      formikProps,
    } = this.props;
    const { affiliatesEnabled } = this.state;
    const errorCheck =
      touched.mailingSignatures &&
      errors.mailingSignatures &&
      touched.mailingSignatures[index] &&
      errors.mailingSignatures[index];

    if (affiliatesEnabled) {
      return (
        <>
          <Grid xs={12}>
            <TextField
              select
              id={`mailingSignatures[${index}][affiliate]`}
              name={`mailingSignatures[${index}][affiliate]`}
              label={
                <Translate>
                  Affiliate (used for every affiliate if blank)
                </Translate>
              }
              defaultValue={values.affiliate ? values.affiliate : ''}
              onChange={(e, key) => {
                handleChange(e);
                this.handleBaseChange(e, key);
              }}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].affiliate
                  ? errors.mailingSignatures[index].affiliate
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].affiliate &&
                Boolean(errors.mailingSignatures[index].affiliate)
              }
            >
              {affiliates.map(this.renderSelect)}
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <Tags
              name={`mailingSignatures[${index}]`}
              handleChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              values={values}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][position]`}
              label={<Translate>Position of signatures</Translate>}
              defaultValue={values.position ? values.position.toString() : ''}
              name={`mailingSignatures[${index}][position]`}
              onChange={handleChange}
              helperText={
                errorCheck && errors.mailingSignatures[index].position
                  ? errors.mailingSignatures[index].position
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].position &&
                Boolean(errors.mailingSignatures[index].position)
              }
              onBlur={handleBlur}
            />
          </Grid>
          <Grid xs={12}>
            <FormCheckbox
              name={`mailingSignatures[${index}][fallback]`}
              label={<Translate>Use as fallback</Translate>}
              checked={
                formikProps.values?.mailingSignatures?.[index]?.fallback ??
                false
              }
              setFieldValue={setFieldValue}
              error={
                touched?.mailingSignatures?.[index]?.fallback &&
                errors?.mailingSignatures?.[index]?.fallback
              }
            />
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid xs={12}>
          <TextField
            select
            id={`mailingSignatures[${index}][location]`}
            name={`mailingSignatures[${index}][location]`}
            label={
              <Translate>Location (used for every location if blank)</Translate>
            }
            defaultValue={values.location ? values.location : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.mailingSignatures[index].location
                ? errors.mailingSignatures[index].location
                : ''
            }
            error={
              errorCheck &&
              errors.mailingSignatures[index].location &&
              Boolean(errors.mailingSignatures[index].location)
            }
          >
            {locations.map(this.renderSelect)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <Tags
            name={`mailingSignatures[${index}]`}
            handleChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            values={values}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            id={`mailingSignatures[${index}][position]`}
            label={<Translate>Position of signatures</Translate>}
            defaultValue={
              typeof values.position !== 'undefined'
                ? values.position.toString()
                : ''
            }
            name={`mailingSignatures[${index}][position]`}
            onChange={handleChange}
            helperText={
              errorCheck && errors.mailingSignatures[index].position
                ? errors.mailingSignatures[index].position
                : ''
            }
            error={
              errorCheck &&
              errors.mailingSignatures[index].position &&
              Boolean(errors.mailingSignatures[index].position)
            }
            onBlur={handleBlur}
          />
        </Grid>
        <Grid xs={12}>
          <FormCheckbox
            name={`mailingSignatures[${index}][fallback]`}
            label={<Translate>Use as fallback</Translate>}
            checked={
              formikProps.values?.mailingSignatures?.[index]?.fallback ?? false
            }
            setFieldValue={setFieldValue}
            error={
              touched?.mailingSignatures?.[index]?.fallback &&
              errors?.mailingSignatures?.[index]?.fallback
            }
          />
        </Grid>
      </>
    );
  };

  renderFieldset = (values) => {
    const {
      index,
      mailings,
      signeeId,
      touched,
      errors,
      handleChange,
      handleBlur,
    } = this.props;
    const errorCheck =
      touched.mailingSignatures &&
      errors.mailingSignatures &&
      touched.mailingSignatures[index] &&
      errors.mailingSignatures[index];

    return (
      <>
        <Input
          type="hidden"
          name={`mailingSignatures[${index}][signee]`}
          value={signeeId ?? ''}
        />
        <Input
          type="hidden"
          name={`mailingSignatures[${index}][id]`}
          value={values.id ? values.id : ''}
        />
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][width]`}
              label={<Translate>Width</Translate>}
              name={`mailingSignatures[${index}][width]`}
              defaultValue={
                typeof values.width !== 'undefined' ? values.width : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].width
                  ? errors.mailingSignatures[index].width
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].width &&
                Boolean(errors.mailingSignatures[index].width)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][height]`}
              label={<Translate>Height</Translate>}
              name={`mailingSignatures[${index}][height]`}
              defaultValue={
                typeof values.height !== 'undefined' ? values.height : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].height
                  ? errors.mailingSignatures[index].height
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].height &&
                Boolean(errors.mailingSignatures[index].height)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][x]`}
              label={<Translate>X</Translate>}
              name={`mailingSignatures[${index}][x]`}
              defaultValue={typeof values.x !== 'undefined' ? values.x : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].x
                  ? errors.mailingSignatures[index].x
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].x &&
                Boolean(errors.mailingSignatures[index].x)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][y]`}
              label={<Translate>Y</Translate>}
              name={`mailingSignatures[${index}][y]`}
              defaultValue={typeof values.y !== 'undefined' ? values.y : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].y
                  ? errors.mailingSignatures[index].y
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].y &&
                Boolean(errors.mailingSignatures[index].y)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Legend</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              id={`mailingSignatures[${index}][legend]`}
              label={<Translate>Legend</Translate>}
              name={`mailingSignatures[${index}][legend]`}
              defaultValue={values.legend ? values.legend : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].legend
                  ? errors.mailingSignatures[index].legend
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].legend &&
                Boolean(errors.mailingSignatures[index].legend)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][offsetLegendX]`}
              label={<Translate>Offset x</Translate>}
              name={`mailingSignatures[${index}][offsetLegendX]`}
              defaultValue={
                typeof values.offsetLegendX !== 'undefined'
                  ? values.offsetLegendX.toString()
                  : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].offsetLegendX
                  ? errors.mailingSignatures[index].offsetLegendX
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].offsetLegendX &&
                Boolean(errors.mailingSignatures[index].offsetLegendX)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id={`mailingSignatures[${index}][offsetLegendY]`}
              label={<Translate>Offset y</Translate>}
              name={`mailingSignatures[${index}][offsetLegendY]`}
              defaultValue={
                typeof values.offsetLegendY !== 'undefined'
                  ? values.offsetLegendY.toString()
                  : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].offsetLegendY
                  ? errors.mailingSignatures[index].offsetLegendY
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].offsetLegendY &&
                Boolean(errors.mailingSignatures[index].offsetLegendY)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Mailing</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              id={`mailingSignatures[${index}][mailing]`}
              name={`mailingSignatures[${index}][mailing]`}
              label={<Translate>Mailing</Translate>}
              defaultValue={values.mailing ? values.mailing : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].mailing
                  ? errors.mailingSignatures[index].mailing
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].mailing &&
                Boolean(errors.mailingSignatures[index].mailing)
              }
            >
              {mailings.map(this.renderSelect)}
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              id={`mailingSignatures[${index}][mode]`}
              name={`mailingSignatures[${index}][mode]`}
              label={<Translate>Signature Mode</Translate>}
              defaultValue={values.mode ? values.mode : 'standard'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.mailingSignatures[index].mode
                  ? errors.mailingSignatures[index].mode
                  : ''
              }
              error={
                errorCheck &&
                errors.mailingSignatures[index].mode &&
                Boolean(errors.mailingSignatures[index].mode)
              }
            >
              <MenuItem value={'standard'}>Standard</MenuItem>
              <MenuItem value={'voucher'}>Gutschein</MenuItem>
            </TextField>
          </Grid>
          {this.renderLocation(values)}
        </Grid>
      </>
    );
  };

  render() {
    const { mailings, values, locations } = this.props;
    const { expanded, currentMailing, currentBase } = this.state;

    if (!mailings || !values || !locations) {
      return null;
    }

    return (
      <Card sx={{ p: 0 }}>
        <CardHeader
          title={
            currentMailing ? (
              currentMailing.primaryText +
              (currentBase ? ' - ' + currentBase.primaryText : '')
            ) : (
              <Translate>Signature - select a Mailing</Translate>
            )
          }
          subheader={<Translate>{values.tag}</Translate>}
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
              sx={expanded ? { transform: 'rotate(180deg)' } : {}}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            {this.renderFieldset(values)}
          </Collapse>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="error" onClick={this.handleRemove}>
            <ContentRemove />
            <Translate>remove</Translate>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

const makeMapStateToProps = () => {
  const getMailingsByPrintable = makeMailingsByPrintable();
  const getHeadLocations = makeHeadLocations();
  const getSelectableAffiliates = makeSelectableAffiliates();

  return (state, props) => {
    const {
      entities: {
        config: { config: config },
        owner,
      },
    } = state;

    return {
      inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
      mailings: values(getMailingsByPrintable(state, props)),
      locations: values(getHeadLocations(state, props)),
      affiliates: values(getSelectableAffiliates(state, props)),
    };
  };
};

export default connect(makeMapStateToProps, {})(Signature);
