import React, { Component, createRef } from 'react';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, merge } from 'lodash';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import { Chip, Input, MenuItem, TextField } from '@mui/material';
import { loadModule, initForm, loadModuleTags } from '../actions';
import { Autocomplete } from '@mui/material';
import ReactTimeout from 'react-timeout';
import EmailEditor from 'react-email-editor';
import Config from '../../../utils/EmailEditor/Config';
import Variables from '../../../utils/EmailEditor/Variables';
import ValidationErrors from '../../../ValidationErrors';
import FormHelperText from '@mui/material/FormHelperText';
import LinkTypes from '../../../utils/EmailEditor/LinkTypes';
import { compose } from 'redux';
import Grid from '@mui/material/Unstable_Grid2';

const validationSchema = Yup.object().shape({
  configKey: Yup.string().required(ValidationErrors.required),
  name: Yup.string().required(ValidationErrors.required),
  category: Yup.string().required(ValidationErrors.required),
  blockDesign: Yup.string()
    .nullable()
    .required(ValidationErrors.requiredBlockDesign),
  tags: Yup.array()
    .of(Yup.object().shape({ name: Yup.string() }))
    .nullable(),
  peculiarity: Yup.object().shape({ name: Yup.string() }).nullable(),
});

class EmailModuleEdit extends Component {
  editor = createRef();

  constructor(props) {
    super(props);
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.formikProps = null;

    this.blockDesign = {
      category: 'other',
      tags: [],
      data: {
        cells: [1],
        columns: [
          {
            contents: [],
            values: {
              _meta: {
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: '0px',
          hideDesktop: false,
          _meta: {
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      displayMode: 'email',
    };
  }

  static propTypes = {
    loadModule: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    initForm: PropTypes.func.isRequired,
    loadModuleTags: PropTypes.func.isRequired,
    values: PropTypes.object,
    token: PropTypes.string,
    duplicate: PropTypes.bool,
  };

  static defaultProps = {
    layoutId: null,
    values: {},
    duplicate: false,
  };

  state = {
    tagsLoaded: false,
    emptyEditorError: false,
  };

  componentDidMount = () => {
    if (this.props.moduleId) {
      this.props.loadModule(this.props.moduleId);
    } else {
      this.props.initForm();
    }
    Promise.all([this.props.loadModuleTags()]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        this.setState({ tagsLoaded: true });
      }
    });
    this.isComponentMounted = true;
    this.props.setTimeout(this.loadTemplate, 1);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.moduleId, this.props.moduleId) ||
      !isEqual(nextProps.form, this.props.form) ||
      !isEqual(nextProps.moduleTags, this.props.moduleTags) ||
      !isEqual(nextState.tagsLoaded, this.state.tagsLoaded) ||
      !isEqual(nextState.emptyEditorError, this.state.emptyEditorError) ||
      !isEqual(nextProps.token, this.props.token)
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.moduleId !== prevProps.moduleId) {
      this.props.loadModule(this.props.moduleId);
    }
  }

  renderEditor = (props) => {
    const { module } = this.props;
    const { touched, errors } = props;
    const { emptyEditorError } = this.state;

    let blocks = !isEmpty(module.blockDesign)
      ? { blocks: [JSON.parse(module.blockDesign)] }
      : {};
    let editorRestriction = {
      editor: {
        minRows: 0,
        maxRows: 1,
      },
    };

    return (
      <>
        <Grid xs={12}>
          {emptyEditorError ||
          (touched.blockDesign && Boolean(errors.blockDesign)) ? (
            <FormHelperText error={true}>{errors.blockDesign}</FormHelperText>
          ) : null}
          <EmailEditor
            ref={this.editor}
            options={merge(
              {},
              Config.base,
              blocks,
              editorRestriction,
              { panels: { body: false } },
              { mergeTagsConfig: { sort: false } } /*Config.layout*/,
            )}
            onReady={this.onEmailEditorLoad}
            minHeight={'500px'}
          />
        </Grid>
      </>
    );
  };

  mapTemplateToBlock = (design) => {
    this.blockDesign.data.cells = design.body.rows[0].cells;
    this.blockDesign.data.columns = design.body.rows[0].columns;
    this.blockDesign.data.values = design.body.values;

    this.blockDesign.data.values._meta.htmlClassNames = 'u_row';
    delete this.blockDesign.data.values.preheaderText;
    delete this.blockDesign.data.values.contentWidth;
    delete this.blockDesign.data.values.contentAlign;
    delete this.blockDesign.data.values.linkStyle;
    delete this.blockDesign.data.values.textColor;
    delete this.blockDesign.data.values.fontFamily;
    delete this.blockDesign.data.values.hideDesktop;

    this.blockDesign.tags = !isEmpty(this.formikProps.values.tags)
      ? this.formikProps.values.tags.map((tag) => tag.name)
      : [];
    this.blockDesign.category = this.formikProps.values.category;
  };

  loadTemplate = () => {
    const { module, moduleId } = this.props;

    if (
      !this.isEditorLoaded ||
      !this.isComponentMounted ||
      !this.editor.current.editor ||
      (moduleId && isEmpty(module))
    ) {
      return;
    }

    this.editor.current.editor.addEventListener('design:updated', () => {
      this.setState({ edited: true });
      this.editor.current.editor.exportHtml((editorData) => {
        const { design } = editorData;
        this.formikProps.setFieldValue('jsonBody', JSON.stringify(design));

        if (
          !isEmpty(design.body.rows[0]) &&
          !isEmpty(design.body.rows[0].columns[0].contents)
        ) {
          this.mapTemplateToBlock(design);

          this.formikProps.setFieldValue(
            'blockDesign',
            JSON.stringify(this.blockDesign),
          );
          this.setState({ emptyEditorError: false });
        } else {
          this.formikProps.setFieldValue('blockDesign', null);
          this.setState({ emptyEditorError: true });
        }
      });
    });

    this.editor.current.editor.addEventListener('design:loaded', () => {
      this.editor.current.editor.exportHtml((editorData) => {
        const { design } = editorData;
        this.setState({ jsonBody: JSON.stringify(design) });
        this.formikProps.setFieldValue('jsonBody', JSON.stringify(design));

        if (
          !isEmpty(design.body.rows[0]) &&
          !isEmpty(design.body.rows[0].columns[0].contents)
        ) {
          this.mapTemplateToBlock(design);

          this.formikProps.setFieldValue(
            'blockDesign',
            JSON.stringify(this.blockDesign),
          );
        } else {
          this.formikProps.setFieldValue('blockDesign', null);
          this.setState({ emptyEditorError: true });
        }
      });
    });

    this.editor.current.editor.setMergeTags(Variables);
    this.editor.current.editor.setLinkTypes(LinkTypes);

    if (!isEmpty(module) && module.jsonBody) {
      const design = JSON.parse(module.jsonBody);
      this.editor.current.editor.loadDesign(design);
      this.formikProps.setFieldValue('jsonBody', module.jsonBody);
    }
  };

  onEmailEditorLoad = () => {
    this.isEditorLoaded = true;
    this.props.setTimeout(this.loadTemplate, 1);
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;
    this.formikProps = props;

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              name="name"
              defaultValue={values.name ? values.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              name="category"
              label={<Translate>Category</Translate>}
              onChange={(e) => {
                handleChange(e);
                this.blockDesign.category = e.target.value;
                props.setFieldValue(
                  'blockDesign',
                  JSON.stringify(this.blockDesign),
                );
              }}
              onBlur={handleBlur}
              helperText={touched.category ? errors.category : ''}
              error={touched.category && Boolean(errors.category)}
              value={values.category ? values.category : 'layout'}
            >
              {/*{affiliates ? affiliates.map(this.renderSelect) : null}*/}
              <MenuItem key={Math.random()} value={'layout'}>
                <Translate>module_category_layout</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'product'}>
                <Translate>module_category_product</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'service'}>
                <Translate>module_category_service</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'template'}>
                <Translate>module_category_template</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'image'}>
                <Translate>module_category_image</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'vehicles'}>
                <Translate>module_category_vehicles</Translate>{' '}
              </MenuItem>
              <MenuItem key={Math.random()} value={'other'}>
                <Translate>module_category_other</Translate>{' '}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Config key</Translate>}
              name="configKey"
              defaultValue={values.configKey ? values.configKey : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.configKey ? errors.configKey : ''}
              error={touched.configKey && Boolean(errors.configKey)}
            />
          </Grid>
          {this.renderPeculiarity(props)}
          {this.renderTags(props)}
          {this.renderEditor(props)}
        </Grid>
      </>
    );
  };

  renderPeculiarity = (props) => {
    const { moduleTags } = this.props;
    const { tagsLoaded } = this.state;
    const { values, handleBlur, touched, errors, handleChange } = props;

    if (!tagsLoaded) {
      return null;
    }

    return (
      <Grid xs={12} sm={6}>
        <Autocomplete
          autoSelect
          fullWidth
          onBlur={handleBlur}
          id="peculiarity[name]"
          options={Object.values(moduleTags).map((option) => option.name)}
          value={
            values.peculiarity && values.peculiarity.name
              ? values.peculiarity.name
              : ''
          }
          freeSolo
          sx={{
            display: 'inline-flex',
            backgroundColor: 'transparent',
          }}
          onChange={(e, value) => {
            props.setFieldValue('peculiarity[name]', value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={touched.peculiarity ? errors.peculiarity : ''}
              error={touched.peculiarity && Boolean(errors.peculiarity)}
              label={<Translate>Peculiarity</Translate>}
              placeholder={'Ausprägung wählen oder neu erstellen'}
            />
          )}
        />
      </Grid>
    );
  };

  renderTags = (props) => {
    const { moduleTags } = this.props;
    const { tagsLoaded } = this.state;
    const { values, handleBlur, touched, errors } = props;

    if (!tagsLoaded) {
      return null;
    }

    return (
      <Grid xs={12} sm={6}>
        <Autocomplete
          multiple
          autoSelect
          fullWidth
          onBlur={handleBlur}
          id="tags"
          options={Object.values(moduleTags)}
          getOptionLabel={(value) => value.name}
          value={values.tags ? values.tags : []}
          freeSolo
          sx={{
            backgroundColor: 'transparent',
          }}
          onChange={(e, value) => {
            if (
              value.length !==
              (props.values.tags ? props.values.tags.length : 0)
            ) {
              props.setFieldValue(
                'tags',
                value.map((option) => {
                  return typeof option === 'string'
                    ? { name: option }
                    : { name: option.name };
                }),
              );
              this.blockDesign.tags = value.map((tag) => tag.name);
              props.setFieldValue(
                'blockDesign',
                JSON.stringify(this.blockDesign),
              );
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={touched.tags ? errors.tags : ''}
              error={touched.tags && Boolean(errors.tags)}
              label={<Translate>Filter Tags</Translate>}
              placeholder="tippen..."
            />
          )}
        />
      </Grid>
    );
  };

  render() {
    const {
      moduleId,
      module,
      duplicate,
      onMount,
      onSubmit,
      onValidityChange,
      token,
      form,
      onCancel,
    } = this.props;
    const { ...defaultValues } = this.props.values;

    let initialValues;

    if (moduleId && isEmpty(module)) {
      return null;
    }

    if (!moduleId && isEmpty(form)) {
      return null;
    }

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
      defaultValues.tags = [];
      defaultValues.category = 'layout';
      initialValues = defaultValues;
    }

    if (moduleId && !isEmpty(module)) {
      initialValues = merge({}, module, { jsonBody: module.jsonBody });
    }

    let headline = module.name ? (
      module.name
    ) : (
      <Translate>New module</Translate>
    );

    if (duplicate) {
      delete initialValues.name;
      delete initialValues.configKey;
      headline = <Translate>New module</Translate>;
    }

    return (
      <Form
        onMount={onMount}
        onCancel={onCancel}
        validationSchema={validationSchema}
        headline={headline}
        values={module}
        initialValues={!isEmpty(initialValues) ? initialValues : {}}
        onSubmit={onSubmit}
        onValidityChange={onValidityChange}
        name="email_module"
        renderFieldset={this.renderFieldset}
        renderTags={this.renderTags}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const params = ownProps?.match?.params;

  const moduleId = params.moduleId ? params.moduleId : null;
  const mode = params.mode;

  const {
    entities: { emailModule: modules, moduleTag: moduleTags },
    forms: { emailModule: form },
  } = state;

  return {
    duplicate: mode === 'duplicate',
    moduleId: moduleId,
    modules: modules ? modules : {},
    module: moduleId ? modules[moduleId] : {},
    token: !isEmpty(form) ? form.updateToken : '',
    form: form,
    moduleTags: moduleTags ? moduleTags : {},
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { loadModule, initForm, loadModuleTags }),
  ReactTimeout,
);

export default enhance(EmailModuleEdit);
