import React, { Component } from 'react';
import {
  IconButton,
  TextField,
  MenuItem,
  Input,
  Typography,
} from '@mui/material';

import { connect } from 'react-redux';
import { initForm, loadLocations } from '../actions';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { loadAffiliates } from '../../Affiliate/actions';
import {
  makeLocationWithChildren,
  makeSelectableAffiliates,
} from '../selectors_deprecated';
import Tags from '../../Affiliate/components/Tags';
import RepresentedBy from '../../Affiliate/components/RepresentedBy';
import Child from './Child';
import { isEqual, isEmpty, values, merge, concat } from 'lodash';
import ContentAdd from '@mui/icons-material/Add';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import FormHelperText from '@mui/material/FormHelperText';
import ValidationRules from '../../../ValidationRules';
import { outdateEntity, resetEntity } from '../../../actions';
import Grid from '@mui/material/Unstable_Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';

let validationSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.required),
  address: Yup.string().nullable(),
  zip: Yup.string().nullable(),
  city: Yup.string().nullable(),
  poBox: Yup.string().nullable(),
  poZip: Yup.string().nullable(),
  displayNamePattern: Yup.string().required(ValidationErrors.required),
  phone: Yup.string()
    .matches(ValidationRules.phoneRegex, { message: ValidationErrors.phone })
    .nullable(),
  fax: Yup.string()
    .matches(ValidationRules.phoneRegex, { message: ValidationErrors.phone })
    .nullable(),
  www: Yup.string().url(ValidationErrors.url).nullable(),
  wwwAsQr: Yup.bool(),
  email: Yup.string().nullable().email(ValidationErrors.email),
  configKey: Yup.string().nullable(),
  signature: Yup.string().nullable(),
  appointmentPhone: Yup.string()
    .matches(ValidationRules.phoneRegex, { message: ValidationErrors.phone })
    .nullable(),
  appointmentWww: Yup.string().url(ValidationErrors.url).nullable(),
  appointmentEmail: Yup.string().nullable().email(ValidationErrors.email),
  representation: Yup.string().nullable(),
  tag: Yup.string().nullable(),
  sorting: Yup.string().nullable(),
  headquarter: Yup.bool(),
  affiliateHead: Yup.bool(),
  children: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(ValidationErrors.required),
      address: Yup.string().nullable(),
      zip: Yup.string().nullable(),
      city: Yup.string().nullable(),
      poBox: Yup.string().nullable(),
      poZip: Yup.string().nullable(),
      displayNamePattern: Yup.string().required(ValidationErrors.required),
      phone: Yup.string()
        .matches(ValidationRules.phoneRegex, {
          message: ValidationErrors.phone,
        })
        .nullable(),
      fax: Yup.string()
        .matches(ValidationRules.phoneRegex, {
          message: ValidationErrors.phone,
        })
        .nullable(),
      www: Yup.string().url(ValidationErrors.url).nullable(),
      wwwAsQr: Yup.bool(),
      email: Yup.string().nullable().email(ValidationErrors.email),
      configKey: Yup.string().nullable(),
      signature: Yup.string().nullable(),
      appointmentPhone: Yup.string()
        .matches(ValidationRules.phoneRegex, {
          message: ValidationErrors.phone,
        })
        .nullable(),
      appointmentWww: Yup.string().url(ValidationErrors.url).nullable(),
      appointmentEmail: Yup.string().nullable().email(ValidationErrors.email),
      representation: Yup.string().nullable(),
      tag: Yup.string().required(ValidationErrors.requiredSelect),
      sorting: Yup.string().nullable(),
    }),
  ),
});

class Edit extends Component {
  static propTypes = {
    locationId: PropTypes.number,
    loadLocations: PropTypes.func.isRequired,
    initForm: PropTypes.func.isRequired,
    loadAffiliates: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    token: PropTypes.string,
    affiliates: PropTypes.array,
    style: PropTypes.object,
  };

  static defaultProps = {
    affiliates: [],
    values: {
      sorting: '0',
      displayNamePattern: '%1$s (%2$s, %3$s %4$s)',
      headquarter: false,
      affiliateHead: false,
    },
    style: {},
  };

  state = {
    key: false,
    affiliatesEnabled: false,
    children: [],
  };

  componentDidMount = () => {
    this.setState({
      key: Math.random(),
      affiliatesEnabled: this.props.inMbvdMode,
    });
    this.props.loadAffiliates();

    if (!this.props.locationId) {
      this.props.initForm();
    }

    if (this.props.locationId) {
      Promise.all([this.props.loadLocations()]).then(
        () => {
          this.setState({
            children: this.props.location ? this.props.location.children : [],
          });
        },
        () => {},
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.loggedIn, prevProps.loggedIn) &&
      this.props.loggedIn
    ) {
      this.props.resetEntity('location');
      location.reload();
      return;
    }

    if (this.props.locationId !== prevProps.locationId) {
      Promise.all([this.props.loadLocation(this.props.locationId)]).then(
        () => {
          this.setState({ children: this.props.values.children });
        },
        () => {},
      );
    }

    if (!isEqual(this.props.affiliates, prevProps.affiliates)) {
      this.setState({ key: Math.random() });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.location, nextProps.location) ||
      !isEqual(this.props.affiliates, nextProps.affiliates) ||
      !isEqual(this.state.children, nextState.children) ||
      !isEqual(this.props.token, nextProps.token) ||
      !isEqual(this.props.form, nextProps.form) ||
      !isEqual(this.props.errors, nextProps.errors) ||
      !isEqual(this.props.loggedIn, nextProps.loggedInBefore) ||
      !isEqual(this.props.touched, nextProps.touched)
    );
  };

  handleChildRemove = (childId) => {
    let children = this.state.children;
    children.splice(childId, 1);
    this.setState({ children: children });
  };

  handleChildAdd = () => {
    this.setState({
      children: concat(this.state.children, [
        `new_${this.state.children.length + 1}`,
      ]),
    });
  };

  // eslint-disable-next-line no-unused-vars
  renderSelect = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        <Translate>{item.primaryText}</Translate>{' '}
      </MenuItem>
    );
  };

  renderChild = (item, index, props) => {
    const {
      handleChange,
      handleBlur,
      touched,
      errors,
      submitCount,
      setFieldTouched,
      setFieldValue,
    } = props;
    let values = merge(
      {},
      props.values.children && props.values.children[index]
        ? props.values.children[index]
        : props.values,
      {
        children: null,
        id:
          props.values.children && props.values.children[index]
            ? props.values.children[index].id
            : null,
        isChild: true,
      },
    );

    return (
      <Child
        key={`location-${this.props.locationId}-child-${index}`}
        values={values}
        onRemove={this.handleChildRemove}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        touched={touched}
        errors={errors}
        onAdd={this.handleChildAdd}
        index={index}
        formikProps={props}
        submitCount={submitCount}
        item={item}
        setFieldValue={setFieldValue}
      />
    );
  };

  renderChildren = (props) => {
    const { children } = this.state;

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Tags</Translate>
            <IconButton
              aria-label={<Translate>add</Translate>}
              onClick={this.handleChildAdd}
              sx={{
                width: 40,
                height: 40,
              }}
              size="large"
            >
              <ContentAdd
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </Typography>
        </Grid>
        {children.map((item, index) => this.renderChild(item, index, props))}
      </>
    );
  };

  renderAffiliate = (props) => {
    const { affiliatesEnabled } = this.state;
    let { affiliates, affiliateId } = this.props;
    const { values, handleChange, touched, errors, setFieldValue } = props;

    if (affiliatesEnabled) {
      return (
        <>
          <Grid xs={12} sm={6}>
            <TextField
              select
              name="affiliate"
              label={<Translate>Affiliate</Translate>}
              onChange={handleChange('affiliate')}
              helperText={touched.affiliate ? errors.affiliate : ''}
              error={touched.affiliate && Boolean(errors.affiliate)}
              value={
                values.affiliate
                  ? values.affiliate
                  : affiliateId
                    ? affiliateId
                    : ''
              }
            >
              {affiliates ? affiliates.map(this.renderSelect) : null}
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <RepresentedBy
              name=""
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <Tags
              name=""
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="affiliateHead"
              label={<Translate>Affiliate headquarter?</Translate>}
              checked={props.values?.affiliateHead ?? false}
              setFieldValue={setFieldValue}
              error={touched?.affiliateHead && errors?.affiliateHead}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="headquarter"
              label={<Translate>Car dealership headquarter?</Translate>}
              checked={props.values?.headquarter ?? false}
              setFieldValue={setFieldValue}
              error={touched?.headquarter && errors?.headquarter}
            />
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid xs={12} sm={6}>
          <FormCheckbox
            name="headquarter"
            label={<Translate>Car dealership headquarter?</Translate>}
            checked={props.values?.headquarter ?? false}
            setFieldValue={setFieldValue}
            error={touched?.headquarter && errors?.headquarter}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Tags
            touched={touched}
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
      </>
    );
  };

  renderAppointment = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;
    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Appointment contact</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Phone</Translate>}
            name="appointmentPhone"
            defaultValue={
              values.appointmentPhone ? values.appointmentPhone : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.appointmentPhone ? errors.appointmentPhone : ''}
            error={touched.appointmentPhone && Boolean(errors.appointmentPhone)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Web</Translate>}
            name="appointmentWww"
            defaultValue={values.appointmentWww ? values.appointmentWww : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.appointmentWww ? errors.appointmentWww : ''}
            error={touched.appointmentWww && Boolean(errors.appointmentWww)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Email</Translate>}
            name="appointmentEmail"
            defaultValue={
              values.appointmentEmail ? values.appointmentEmail : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.appointmentEmail ? errors.appointmentEmail : ''}
            error={touched.appointmentEmail && Boolean(errors.appointmentEmail)}
          />
        </Grid>
      </>
    );
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Grid container spacing={3}>
        <Input
          type="hidden"
          name="updateToken"
          value={values.updateToken ? values.updateToken : ''}
        />
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Name</Translate>}
            name="name"
            defaultValue={values.name ? values.name : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Address</Translate>}
            name="address"
            defaultValue={values.address ? values.address : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.address ? errors.address : ''}
            error={touched.address && Boolean(errors.address)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>ZIP</Translate>}
            name="zip"
            defaultValue={values.zip ? values.zip : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.zip ? errors.zip : ''}
            error={touched.zip && Boolean(errors.zip)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>City</Translate>}
            name="city"
            defaultValue={values.city ? values.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.city ? errors.city : ''}
            error={touched.city && Boolean(errors.city)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Postbox</Translate>}
            name="poBox"
            defaultValue={values.poBox ? values.poBox : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.poBox ? errors.poBox : ''}
            error={touched.poBox && Boolean(errors.poBox)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Postbox ZIP</Translate>}
            name="poZip"
            defaultValue={values.poZip ? values.poZip : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.poZip ? errors.poZip : ''}
            error={touched.poZip && Boolean(errors.poZip)}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Contact</Translate>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <TextField
            label={<Translate>Display name pattern</Translate>}
            name="displayNamePattern"
            defaultValue={
              values.displayNamePattern ? values.displayNamePattern : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.displayNamePattern ? errors.displayNamePattern : ''
            }
            error={
              touched.displayNamePattern && Boolean(errors.displayNamePattern)
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Phone</Translate>}
            name="phone"
            defaultValue={values.phone ? values.phone : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.phone ? errors.phone : ''}
            error={touched.phone && Boolean(errors.phone)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Fax</Translate>}
            name="fax"
            defaultValue={values.fax ? values.fax : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.fax ? errors.fax : ''}
            error={touched.fax && Boolean(errors.fax)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Web</Translate>}
            name="www"
            defaultValue={values.www ? values.www : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.www ? errors.www : ''}
            error={touched.www && Boolean(errors.www)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormCheckbox
            name="wwwAsQr"
            label={<Translate>Use web as QR code?</Translate>}
            checked={values?.wwwAsQr ?? false}
            onChange={handleChange}
            error={touched?.wwwAsQr && errors?.wwwAsQr}
          />
        </Grid>
        {touched.wwwAsQr && Boolean(errors.wwwAsQr) ? (
          <Grid xs={12}>
            <FormHelperText error={true}>{errors.wwwAsQr}</FormHelperText>
          </Grid>
        ) : null}
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Email</Translate>}
            name="email"
            defaultValue={values.email ? values.email : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.email ? errors.email : ''}
            error={touched.email && Boolean(errors.email)}
          />
        </Grid>
        {this.renderAppointment(props)}
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Letter paper</Translate> /{' '}
            <Translate>Postcard template</Translate>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Config key</Translate>}
            name="configKey"
            defaultValue={values.configKey ? values.configKey : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.configKey ? errors.configKey : ''}
            error={touched.configKey && Boolean(errors.configKey)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Position location list</Translate>}
            name="sorting"
            defaultValue={values.sorting ? values.sorting.toString() : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.sorting ? errors.sorting : ''}
            error={touched.sorting && Boolean(errors.sorting)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Complimentary close</Translate>}
            name="signature"
            defaultValue={values.signature ? values.signature : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.signature ? errors.signature : ''}
            error={touched.signature && Boolean(errors.signature)}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Settings</Translate>
          </Typography>
        </Grid>
        {this.renderAffiliate(props)}
        {this.renderChildren(props)}
      </Grid>
    );
  };

  render() {
    const {
      location,
      token,
      onSubmit,
      OnMount,
      form,
      locationId,
      onCancel,
      locationWithChildren,
      affiliateId,
    } = this.props;
    let { ...defaultValues } = this.props.values;

    if (locationId && isEmpty(location)) {
      // if locationId is set location should not be empty
      return null;
    }

    if (!locationId && isEmpty(form)) {
      // if locationId is missing form should not be empty
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    let headline = !isEmpty(location) ? (
      location.name
    ) : (
      <Translate>New location</Translate>
    );

    return (
      <Form
        onMount={OnMount}
        onSubmit={onSubmit}
        onCancel={onCancel}
        headline={headline}
        validationSchema={validationSchema}
        initialValues={
          !isEmpty(location)
            ? locationWithChildren
            : Object.assign(defaultValues, { affiliate: affiliateId })
        }
        name="location"
        renderFieldset={this.renderFieldset}
        values={location}
      />
    );
  }
}

const mapStateToProps = () => {
  const getSelectableAffiliates = makeSelectableAffiliates();
  const getLocationWithChildren = makeLocationWithChildren();

  return (state, props) => {
    const locationId = props.locationId;
    const affiliateId = props.affiliateId;

    const {
      entities: {
        location: locations,
        config: { config: config },
        owner,
      },
      forms: { location: form },
    } = state;

    return {
      inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
      locations: locations,
      location: locations[locationId] ? locations[locationId] : {},
      affiliates: values(getSelectableAffiliates(state, props)),
      affiliateId: affiliateId ? affiliateId : null,
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
      locationWithChildren: getLocationWithChildren(state, props),
      loggedIn: state.loggedIn ? state.loggedIn.loggedIn : false,
    };
  };
};

export default connect(mapStateToProps, {
  initForm,
  loadLocations,
  loadAffiliates,
  outdateEntity,
  resetEntity,
})(Edit);
