import React, { Component } from 'react';
import { isEmpty, isEqual, union, pickBy } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import DockedList from './components/DockedList';
import CustomerListCustomer from '../../components/CustomerListCustomer';
import EmailPreview from './components/EmailPreview';
import SendEmailDialog from './components/SendEmailDialog';
import Translate from '../../components/service/Translate';
import ToolBar from '../../components/ToolBar';
import ErrorBoundary from '../../components/ErrorBoundary';
import ListView from '@mui/icons-material/List';
import TextFormat from '@mui/icons-material/TextFormat';
import FontDownload from '@mui/icons-material/FontDownload';
import AlternateEmail from '../../theme/icons/AlternateEmail';
import NavigationCheck from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  loadEmailQueueCustomersPaged,
  loadEmailQueueEmail,
  loadEmailQueues,
  loadNext,
} from './actions';
import { resetEntity } from '../../actions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { withRouter } from '../../withRouter';
import { makeCustomerListCustomerByCl } from './selectors';
import { styled } from '@mui/system';
import { compose } from 'redux';

const drawerWidth = 256;

const RootList = styled(List)({
  width: '100%',
  maxWidth: 360,
  backgroundColor: 'background.paper',
});

const NestedListItem = styled(ListItem)({
  pl: 4,
});

class EmailQueue extends Component {
  static propTypes = {
    loadEmailQueues: PropTypes.func.isRequired,
    loadEmailQueueCustomersPaged: PropTypes.func.isRequired,
    seasonJobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loadNext: PropTypes.func.isRequired,
    email: PropTypes.object,
    emailJobs: PropTypes.object,
    resultset: PropTypes.array,
    seasonJobs: PropTypes.object,
    seasonType: PropTypes.string,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    jobId: PropTypes.number,
    isPaging: PropTypes.bool,
    pageable: PropTypes.bool,
    onBottomReached: PropTypes.func,
    onMount: PropTypes.func,
    iterableCustomerListCustomer: PropTypes.array,
    resetEntity: PropTypes.func.isRequired,
    filterConditions: PropTypes.object,
    filterOptions: PropTypes.array,
  };

  static defaultProps = {
    seasonType: 'email',
    pagination: false,
    filterConditions: {
      'c.gender.company': {
        type: 'group',
        conjunction: 'OR',
        subfilter: {
          sub1: { field: 'c.gender', type: 'isNull' },
          sub2: { field: 'c.gender', type: 'in', value: 'company' },
        },
      },
      'c.gender.private': {
        field: 'c.gender',
        type: 'in',
        value: ['male', 'female'],
      },
      'c.gender.sample': {
        field: 'c.referenceNumber',
        type: 'string',
        value: 'JP',
      },
      'customer.id.eq': {
        groupBy: 'c.id',
        type: 'groupBy',
        having: { field: 'a.id', value: parseInt('1'), type: 'count_eq' },
      },
      'customer.id.gt': {
        groupBy: 'c.id',
        type: 'groupBy',
        having: { field: 'a.id', value: parseInt('1'), type: 'count_gt' },
      },
      'bc.short.MB': { field: 'bc.short', type: 'in', value: 'MB' },
      'bc.short.sg': { field: 'bc.short', type: 'in', value: 'sg' },
      'bc.short.fuso': { field: 'bc.short', type: 'in', value: 'fuso' },
      'consolidated.no': {
        groupBy: 'c.id',
        type: 'groupBy',
        having: {
          field: 'clc.customer_list',
          value: parseInt('1'),
          type: 'count_eq',
        },
      },
      'consolidated.___': {
        field: 'clc.customer_list',
        type: 'not_in',
        value: '%%',
      },
      'consolidated.yes': {
        groupBy: 'c.id',
        type: 'groupBy',
        having: {
          field: 'clc.customer_list',
          value: parseInt('1'),
          type: 'count_gt',
        },
      },
    },
    filterOptions: [
      {
        carCount: {
          key: 'customer.id',
          title: <Translate>Anzahl Autos</Translate>,
          sub: {
            one: {
              key: 'customer.id.eq',
              title: <Translate>1 Auto</Translate>,
            },
            many: {
              key: 'customer.id.gt',
              title: <Translate>mehr als 1 Auto</Translate>,
            },
          },
        },
      },
      {
        customerType: {
          key: 'c.gender',
          title: <Translate>Kundentyp</Translate>,
          sub: {
            private: {
              key: 'c.gender.private',
              title: <Translate>Privat</Translate>,
            },
            company: {
              key: 'c.gender.company',
              title: <Translate>Firma</Translate>,
            },
            sample: {
              key: 'c.gender.sample',
              title: <Translate>Prüfempfänger</Translate>,
            },
          },
        },
      },
      {
        carType: {
          key: 'bc.short',
          title: <Translate>Fahrzeugtyp</Translate>,
          sub: {
            mercedes: {
              key: 'bc.short.MB',
              title: <Translate>Mercedes-Benz</Translate>,
            },
            smart: {
              key: 'bc.short.sg',
              title: <Translate>smart</Translate>,
            },
            fuso: {
              key: 'bc.short.fuso',
              title: <Translate>FUSO</Translate>,
            },
          },
        },
      },
      {
        consolidated: {
          key: 'consolidated',
          title: <Translate>Konsolidiert</Translate>,
          sub: {
            yes: {
              key: 'consolidated.yes',
              title: <Translate>Ja</Translate>,
            },
            no: {
              key: 'consolidated.no',
              title: <Translate>Nein</Translate>,
            },
          },
        },
      },
    ],
  };

  state = {
    value: 1,
    jobId: 1,
    seasonId: 1,
    format: 'html',
    currentJobId: null,
    currentSeasonId: null,
    openSendForm: false,
    isPaging: false,
    currentSelectedValues: [],
    filter: [],
    seasonAnchorEl: null,
    filterAnchorEl: null,
    subanchorEl: null,
    open: true,
    customerListLoading: false,
  };

  componentDidMount = () => {
    const { customerListCustomerId, seasonJobId, email, resultset } =
      this.props;
    //customerListCustomerId ? this.handleItemSelect(customerListCustomerId): null;

    this.setState({ emailQueueLoading: true });
    Promise.all([this.props.loadEmailQueues()]).then(() => {
      this.setState({
        emailQueueLoading: false /*, customerListLoading: true*/,
      });
      if (seasonJobId) {
        this.setState({ customerListLoading: true });
        Promise.all([
          this.props.loadEmailQueueCustomersPaged(
            seasonJobId,
            this.state.filter,
            [],
            [],
            customerListCustomerId,
          ),
        ]).then(
          () => {
            this.setState({ customerListLoading: false });
          },
          () => {
            this.setState({ customerListLoading: false });
          },
        );
      } else {
        if (
          !isEmpty(email) &&
          !isEmpty(resultset) &&
          email[resultset[0]] &&
          !isEmpty(email[resultset[0]].nextJobs)
        ) {
          this.handleMenuChange(email[resultset[0]].nextJobs[0]);
        }
      }
    });
    this.emailSubject = 'E-Mail Vorschau';
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      seasonJobId,
      customerListCustomerId,
      email,
      iterableCustomerListCustomer,
    } = this.props;

    if (
      !isEqual(this.props.resultset, prevProps.resultset) ||
      !isEqual(seasonJobId, prevProps.seasonJobId)
    ) {
      const resultMail = pickBy(email, (currentEmail) => {
        return (
          (!isEmpty(currentEmail.previousJobs) &&
            currentEmail.previousJobs.includes(seasonJobId)) ||
          (!isEmpty(currentEmail.nextJobs) &&
            currentEmail.nextJobs.includes(seasonJobId))
        );
      });
      this.setState({
        currentSeasonId: !isEmpty(resultMail)
          ? resultMail[Object.keys(resultMail)[0]].season
          : null,
      });
    }

    if (!isEqual(customerListCustomerId, prevProps.customerListCustomerId)) {
      if (
        customerListCustomerId &&
        !iterableCustomerListCustomer.includes(customerListCustomerId)
      ) {
        this.setState({ customerListLoading: true });
        Promise.all([
          this.props.loadEmailQueueCustomersPaged(
            seasonJobId,
            this.state.filter,
            [],
            [],
            customerListCustomerId,
          ),
        ]).then(
          () => {
            this.setState({ customerListLoading: false });
          },
          () => {
            this.setState({ customerListLoading: false });
          },
        );
      }
      if (!customerListCustomerId && !isEmpty(iterableCustomerListCustomer)) {
        this.props.navigate(
          '/email/queue/customer/' +
            seasonJobId +
            '/' +
            iterableCustomerListCustomer[0],
        );
      }
    }

    if (!isEqual(seasonJobId, prevProps.seasonJobId)) {
      this.setState({ customerListLoading: true });
      Promise.all([
        this.props.loadEmailQueueCustomersPaged(
          seasonJobId,
          this.state.filter,
          [],
          [],
          customerListCustomerId,
        ),
      ]).then(
        () => {
          this.setState({ customerListLoading: false });
        },
        () => {
          this.setState({ customerListLoading: false });
        },
      );
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(this.state, nextState) ||
      !isEqual(this.props.seasons, nextProps.seasons) ||
      !isEqual(this.props.email, nextProps.email) ||
      !isEqual(this.props.emailJobs, nextProps.emailJobs) ||
      !isEqual(this.props.pagination, nextProps.pagination) ||
      !isEqual(
        this.props.iterableCustomerListCustomer,
        nextProps.iterableCustomerListCustomer,
      ) ||
      !isEqual(this.props.seasonJobId, nextProps.seasonJobId) ||
      !isEqual(
        this.props.customerListCustomerId,
        nextProps.customerListCustomerId,
      ) ||
      !isEqual(this.props.resultset, nextProps.resultset)
    );
  }

  setFormat = () => {
    if (this.state.format === 'text') {
      this.setState({ format: 'html' });
      return;
    }

    this.setState({ format: 'text' });
  };

  handleSeasonClick = (e, index) => {
    this.setState({ [index]: !this.state[index] });
  };

  handleMenuChange = (jobId) => {
    const { email } = this.props;

    const resultMail = pickBy(email, (currentEmail) => {
      return (
        (!isEmpty(currentEmail.previousJobs) &&
          currentEmail.previousJobs.includes(jobId)) ||
        (!isEmpty(currentEmail.nextJobs) &&
          currentEmail.nextJobs.includes(jobId))
      );
    });
    this.setState({
      currentSeasonId: resultMail[Object.keys(resultMail)[0]].season,
      currentSelectedValues: [],
      filter: {},
    });
    this.handleSeasonMenuClose();
    this.props.navigate('/email/queue/customer/' + jobId);
  };

  getSeasonSubMenu = (jobId) => {
    const { emailJobs, seasonJobId } = this.props;
    const job = emailJobs[jobId] ? emailJobs[jobId] : null;
    let activeJob = seasonJobId === job.id;

    return (
      <NestedListItem
        key={`menu-emailqueue-job-${jobId}`}
        button
        selected={activeJob}
        value={job.id}
        onClick={() => this.handleMenuChange(job.id)}
      >
        <ListItemText inset primary={job.primaryText} />
        {activeJob ? <NavigationCheck /> : ''}
      </NestedListItem>
    );
  };

  renderSeasonMenu = (emailId) => {
    const { email, seasons } = this.props;
    const { currentSeasonId } = this.state;

    const currentEmail = email[emailId] ? email[emailId] : null;
    const season =
      currentEmail.season && seasons[currentEmail.season]
        ? seasons[currentEmail.season]
        : null;
    const activeSeason = currentSeasonId === season.id;
    const seasonJob = season.id;

    if (currentEmail && currentEmail.nextJobs && season) {
      const nextJobs = currentEmail.nextJobs;
      const previousJobs = currentEmail.previousJobs;
      const jobs = currentEmail.previousJobs
        ? union(previousJobs, nextJobs)
        : nextJobs;

      return (
        <div key={`menu-emailqueue-main-${season.id}`}>
          <ListItem
            button
            onClick={(e) => {
              season.cycle === 'singular'
                ? this.handleMenuChange(jobs[0])
                : this.handleSeasonClick(e, seasonJob);
            }}
            key={`menu-emailqueue-${season.id}`}
            value={season.id}
            selected={activeSeason}
          >
            <ListItemText inset primary={season.primaryText} />
            {season.cycle === 'singular' ? null : this.state[seasonJob] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          {season.cycle === 'singular' ? null : (
            <Collapse in={this.state[seasonJob]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {jobs.map(this.getSeasonSubMenu)}
              </List>
            </Collapse>
          )}
        </div>
      );
    }

    return null;
  };

  handleItemSelect = (event, index) => {
    this.props.navigate(
      '/email/queue/customer/' + this.props.seasonJobId + '/' + index,
    );
  };

  handleForward = () => {
    const {
      iterableCustomerListCustomer,
      customerListCustomerId,
      seasonJobId,
    } = this.props;

    let index =
      iterableCustomerListCustomer.indexOf(customerListCustomerId) + 1;

    if (index < iterableCustomerListCustomer.length) {
      this.props.navigate(
        '/email/queue/customer/' +
          seasonJobId +
          '/' +
          iterableCustomerListCustomer[index],
      );
    }
  };

  handleBack = () => {
    const {
      iterableCustomerListCustomer,
      customerListCustomerId,
      seasonJobId,
    } = this.props;

    let index =
      iterableCustomerListCustomer.indexOf(customerListCustomerId) - 1;
    if (index >= 0) {
      this.props.navigate(
        '/email/queue/customer/' +
          seasonJobId +
          '/' +
          iterableCustomerListCustomer[index],
      );
    }
  };

  handleDockedListMount = (list) => {
    this.list = list;
  };

  handleBottomReached = () => {
    const { pagination, seasonJobId, customerListCustomerId } = this.props;

    if (pagination && pagination.more) {
      this.setState({ isPaging: true });

      Promise.all([
        this.props.loadNext(
          seasonJobId ? seasonJobId : null,
          this.state.filter,
          [],
          [],
          customerListCustomerId,
        ),
      ]).then(
        () => {
          this.setState({ isPaging: false });
        },
        () => {
          this.setState({ isPaging: false });
        },
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  resetFilter = (event, selected) => {
    const { seasonJobId, customerListId } = this.props;
    this.setState({ currentSelectedValues: [], filter: {} });
    this.props.resetEntity('customerList', customerListId);
    this.props.loadEmailQueueCustomersPaged(seasonJobId, {}, [], [], null);
    this.handleFilterMenuClose();
  };

  handleFilterClick = (e, index) => {
    this.setState({ [index]: !this.state[index] });
  };

  renderFilterSubmenu = (options) => {
    const { currentSelectedValues } = this.state;

    return (
      <NestedListItem
        key={`filterOptions-${options.key}`}
        button
        value={options.key}
        onClick={() => {
          this.handleFilter(event, options.key);
        }}
      >
        <ListItemText inset primary={options.title} />
        <ListItemIcon>
          {currentSelectedValues.includes(options.key) ? (
            <NavigationCheck />
          ) : null}
        </ListItemIcon>
      </NestedListItem>
    );
  };

  // eslint-disable-next-line no-unused-vars
  renderMenuItem = (filter, index) => {
    const { currentSelectedValues } = this.state;
    let checked = currentSelectedValues.some((s) => s.includes(filter.key));
    let filterKey = filter.key;
    return (
      <RootList key={index} component="nav">
        <ListItem
          button
          onClick={(e) => {
            this.handleFilterClick(e, filterKey);
          }}
          key={`filter-${filter.key}`}
          value={filter.key}
          selected={checked}
        >
          <ListItemText inset primary={filter.title} />
          {this.state[filterKey] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state[filterKey]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {filter.sub
              ? Object.values(filter.sub).map(this.renderFilterSubmenu)
              : null}
          </List>
        </Collapse>
      </RootList>
    );
  };

  renderMenu = (filterOptions) => {
    return Object.values(filterOptions).map((options) =>
      Object.values(options).map(this.renderMenuItem),
    );
  };

  getParentKey = (key) => {
    return key.substring(0, key.lastIndexOf('.'));
  };

  handleFilter = (event, selected) => {
    let { currentSelectedValues } = this.state;
    let { filterConditions, customerListId, seasonJobId } = this.props;

    let newSelectedValues = [...currentSelectedValues];
    let newFilter = {};
    let selectedParentKey = this.getParentKey(selected);

    if (newSelectedValues.includes(selected)) {
      newSelectedValues = newSelectedValues.filter(
        (s) => !s.includes(selected),
      );
    } else {
      if (
        newSelectedValues.some(
          (x) => this.getParentKey(x) === selectedParentKey,
        )
      ) {
        newSelectedValues = newSelectedValues.filter(
          (x) => this.getParentKey(x) !== selectedParentKey,
        );
      }
      newSelectedValues.push(selected);
    }

    for (var i = 0; i < newSelectedValues.length; i++) {
      newFilter[newSelectedValues[i]] = filterConditions[newSelectedValues[i]];
    }

    this.setState({ currentSelectedValues: newSelectedValues });
    this.setState({ filter: newFilter });

    this.props.resetEntity('customerList', customerListId);
    this.props.loadEmailQueueCustomersPaged(
      seasonJobId,
      newFilter,
      [],
      [],
      null,
    );

    this.handleFilterMenuClose();
  };

  openSendDialog = () => {
    this.setState({ openSendForm: true });
  };

  closeSendDialog = () => {
    this.setState({ openSendForm: false });
  };

  handleVisibleState = (state) => {
    this.setState({ openSendForm: state });
  };

  handleSeasonMenuClick = (event) => {
    this.setState({ seasonAnchorEl: event.currentTarget });
  };

  handleSeasonMenuClose = () => {
    this.setState({ seasonAnchorEl: null });
  };

  handleFilterMenuClick = (event) => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterMenuClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  renderToolbarTitle = () => {
    const { seasons, emailJobs, seasonJobId } = this.props;
    const { currentSeasonId } = this.state;

    if (
      currentSeasonId &&
      seasons[currentSeasonId] &&
      seasonJobId &&
      emailJobs[seasonJobId]
    ) {
      return `${seasons[currentSeasonId].primaryText} ${emailJobs[seasonJobId].primaryText}`;
    }

    return 'E-Mail Vorschau';
  };

  renderToolbarMenu = () => {
    const { resultset } = this.props;

    let textIcon = <TextFormat />;
    let htmlIcon = <FontDownload />;

    const season = resultset ? Object.values(resultset) : [];
    const { seasonAnchorEl } = this.state;

    return (
      <div>
        <Tooltip title="Aussand wählen">
          <IconButton
            aria-label="More"
            aria-owns={seasonAnchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            onClick={this.handleSeasonMenuClick}
            size="large"
          >
            <ListView />
          </IconButton>
        </Tooltip>

        <Popover
          id="simple-menu"
          open={Boolean(seasonAnchorEl)}
          anchorEl={seasonAnchorEl}
          onClose={this.handleSeasonMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <RootList component="nav">
            {season.map(this.renderSeasonMenu)}
          </RootList>
        </Popover>

        <Tooltip
          title={`${
            this.state.format === 'html'
              ? 'zur Textansicht wechseln'
              : 'zur normalen Ansicht wechseln'
          }`}
        >
          <IconButton onClick={this.setFormat} size="large">
            {this.state.format === 'html' ? htmlIcon : textIcon}
          </IconButton>
        </Tooltip>

        <Tooltip title={'Vorschau verschicken'}>
          <IconButton onClick={this.openSendDialog} size="large">
            <AlternateEmail />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  render() {
    const { emailQueueLoading, filterAnchorEl, customerListLoading, isPaging } =
      this.state;
    const {
      filterOptions,
      pagination,
      onCall,
      customerListCustomerId,
      seasonJobId,
    } = this.props;

    if (emailQueueLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }

    return (
      <Box
        sx={{
          width: (theme) => `calc(100% - 256px)`,
        }}
      >
        <ToolBar
          renderTitle={this.renderToolbarTitle}
          renderMenu={this.renderToolbarMenu}
          view="grid"
        />
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: drawerWidth,
              top: 64,
              backgroundColor: 'neutral.800',
              zIndex: 1300,
            }}
          >
            <Typography variant="h6" color="inherit" noWrap>
              <div>
                <Tooltip title="Filtern">
                  <IconButton
                    color="inherit"
                    aria-label="More"
                    aria-owns={filterAnchorEl ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleFilterMenuClick}
                    sx={{
                      color: 'body',
                    }}
                    size="large"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id="long-menu"
                  open={Boolean(filterAnchorEl)}
                  anchorEl={filterAnchorEl}
                  onClose={this.handleFilterMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {this.renderMenu(filterOptions)}
                </Popover>
              </div>
            </Typography>
          </AppBar>
          <Drawer
            sx={{
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                top: 64,
              },
            }}
            variant="permanent"
            anchor="right"
          >
            <Box
              sx={{
                backgroundColor: 'transparent',
                width: (theme) => `calc(95% - 256px - 24px)`,
              }}
            />
            <ErrorBoundary>
              <DockedList
                jobId={seasonJobId}
                selectedIndex={customerListCustomerId}
                onMount={this.handleDockedListMount}
                onItemSelect={this.handleItemSelect}
                pageable={pagination !== false}
                isPaging={isPaging}
                onBottomReached={this.handleBottomReached}
              />
            </ErrorBoundary>
          </Drawer>
        </Box>
        <div>
          <Paper
            sx={{
              minHeight: 410,
              mt: 0,
              ml: 0,
              mr: 0,
            }}
          >
            <ErrorBoundary>
              <CustomerListCustomer
                customerListCustomerId={
                  customerListCustomerId ? customerListCustomerId : '1'
                }
                callable={false}
                editable={false}
                onCall={onCall}
                onForward={this.handleForward}
                onBack={this.handleBack}
                dataLoading={customerListLoading}
              ></CustomerListCustomer>
            </ErrorBoundary>
          </Paper>
          <ErrorBoundary>
            <EmailPreview
              format={this.state.format}
              customerListLoading={customerListLoading}
              customerListCustomerId={customerListCustomerId}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <SendEmailDialog
              customerListCustomerId={customerListCustomerId}
              setVisibleState={this.handleVisibleState}
              onCancel={this.closeSendDialog}
              visible={this.state.openSendForm}
            />
          </ErrorBoundary>
        </div>
      </Box>
    );
  }
}

const makeMapStateToProps = () => {
  const getCustomerListCustomerByCl = makeCustomerListCustomerByCl();

  return (state, props) => {
    const { seasonJobId, customerListCustomerId } = props.match.params;
    const {
      entities: { emailJob, season, email },
      resultsets: { email: resultset },
      pagination: { customerList: pagination },
    } = state;

    const customerListId =
      seasonJobId && emailJob && emailJob[seasonJobId]
        ? emailJob[seasonJobId].customerList
        : !isEmpty(emailJob) &&
            !isEmpty(email) &&
            resultset &&
            email[resultset[0]].nextJobs
          ? emailJob[email[resultset[0]].nextJobs[0]].customerList
          : null;

    return {
      customerListCustomerId: customerListCustomerId
        ? parseInt(customerListCustomerId)
        : null,
      seasonJobId: seasonJobId ? parseInt(seasonJobId) : null,
      customerListId: customerListId,
      emailJobs: emailJob,
      seasons: season,
      email: email,
      resultset: resultset,
      pagination:
        pagination && pagination[customerListId]
          ? pagination[customerListId]
          : false,
      iterableCustomerListCustomer: getCustomerListCustomerByCl(state, props),
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    loadEmailQueues,
    loadEmailQueueCustomersPaged,
    loadEmailQueueEmail,
    loadNext,
    resetEntity,
  }),
);

export default enhance(EmailQueue);
