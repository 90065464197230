import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Collapse,
  TextField,
  Input,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import Translate from '../../../components/service/Translate';
import Tags from '../../Affiliate/components/Tags';
import RepresentedBy from '../../Affiliate/components/RepresentedBy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';

class Child extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    values: {},
    style: {},
  };

  state = {
    expanded: false,
  };

  componentDidMount = () => {
    this.props.formikProps.setFieldValue(
      `children[${this.props.index}]`,
      this.props.values,
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.submitCount, prevProps.submitCount) &&
      this.props.errors &&
      this.props.errors.children &&
      this.props.errors.children[this.props.index]
    ) {
      this.setState({ expanded: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.values, nextProps.values) ||
      !isEqual(nextState.expanded, this.state.expanded) ||
      !isEqual(this.props.touched, nextProps.touched) ||
      !isEqual(this.props.errors, nextProps.errors)
    );
  };

  handleExpandChange = (newExpandedState) => {
    this.setState({ expanded: newExpandedState });
  };

  handleRemove = () => {
    this.props.onRemove(this.props.index);
    let children = this.props.formikProps.values.children;
    children.splice(this.props.index, 1);
    this.props.formikProps.setFieldValue('children', children);
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  renderFieldset = (values) => {
    const { index, handleChange, handleBlur, touched, errors, setFieldValue } =
      this.props;
    const errorCheck =
      touched.children &&
      errors.children &&
      touched.children[index] &&
      errors.children[index];

    return (
      <>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              name={`children[${index}][name]`}
              defaultValue={values.name ? values.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].name
                  ? errors.children[index].name
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].name &&
                Boolean(errors.children[index].name)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Address</Translate>}
              name={`children[${index}][address]`}
              defaultValue={values.address ? values.address : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].address
                  ? errors.children[index].address
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].address &&
                Boolean(errors.children[index].address)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>ZIP</Translate>}
              name={`children[${index}][zip]`}
              defaultValue={values.zip ? values.zip : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].zip
                  ? errors.children[index].zip
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].zip &&
                Boolean(errors.children[index].zip)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>City</Translate>}
              name={`children[${index}][city]`}
              defaultValue={values.city ? values.city : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].city
                  ? errors.children[index].city
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].city &&
                Boolean(errors.children[index].city)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Postbox</Translate>}
              name={`children[${index}][poBox]`}
              defaultValue={values.poBox ? values.poBox : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].poBox
                  ? errors.children[index].poBox
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].poBox &&
                Boolean(errors.children[index].poBox)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Postbox ZIP</Translate>}
              name={`children[${index}][poZip]`}
              defaultValue={values.poZip ? values.poZip : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].poZip
                  ? errors.children[index].poZip
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].poZip &&
                Boolean(errors.children[index].poZip)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Contact</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Display name pattern</Translate>}
              name={`children[${index}][displayNamePattern]`}
              defaultValue={
                values.displayNamePattern
                  ? values.displayNamePattern
                  : '%1$s (%2$s, %3$s %4$s)'
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].displayNamePattern
                  ? errors.children[index].displayNamePattern
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].displayNamePattern &&
                Boolean(errors.children[index].displayNamePattern)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Phone</Translate>}
              name={`children[${index}][phone]`}
              defaultValue={values.phone ? values.phone : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].phone
                  ? errors.children[index].phone
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].phone &&
                Boolean(errors.children[index].phone)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Fax</Translate>}
              name={`children[${index}][fax]`}
              defaultValue={values.fax ? values.fax : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].fax
                  ? errors.children[index].fax
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].fax &&
                Boolean(errors.children[index].fax)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Web</Translate>}
              name={`children[${index}][www]`}
              defaultValue={values.www ? values.www : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].www
                  ? errors.children[index].www
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].www &&
                Boolean(errors.children[index].www)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name={`children[${index}][wwwAsQr]`}
              label={<Translate>Use web as QR code?</Translate>}
              checked={values?.children?.[index].wwwAsQr ?? false}
              setFieldValue={setFieldValue}
              error={
                touched?.children?.[index].wwwAsQr &&
                errors?.children?.[index].wwwAsQr
              }
            />
          </Grid>
          {errorCheck &&
          touched.children[index].wwwAsQr &&
          Boolean(errors.children[index].wwwAsQr) ? (
            <Grid xs={12} sm={6}>
              <FormHelperText error={true}>
                {errors.children[index].wwwAsQr}
              </FormHelperText>
            </Grid>
          ) : null}
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Email</Translate>}
              name={`children[${index}][email]`}
              defaultValue={values.email ? values.email : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].email
                  ? errors.children[index].email
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].email &&
                Boolean(errors.children[index].email)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Appointment contact</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Phone</Translate>}
              name={`children[${index}][appointmentPhone]`}
              defaultValue={
                values.appointmentPhone ? values.appointmentPhone : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].appointmentPhone
                  ? errors.children[index].appointmentPhone
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].appointmentPhone &&
                Boolean(errors.children[index].appointmentPhone)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Web</Translate>}
              name={`children[${index}][appointmentWww]`}
              defaultValue={values.appointmentWww ? values.appointmentWww : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].appointmentWww
                  ? errors.children[index].appointmentWww
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].appointmentWww &&
                Boolean(errors.children[index].appointmentWww)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Email</Translate>}
              name={`children[${index}][appointmentEmail]`}
              defaultValue={
                values.appointmentEmail ? values.appointmentEmail : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].appointmentEmail
                  ? errors.children[index].appointmentEmail
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].appointmentEmail &&
                Boolean(errors.children[index].appointmentEmail)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Letter paper</Translate> /{' '}
              <Translate>Postcard template</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Config key</Translate>}
              name={`children[${index}][configKey]`}
              defaultValue={values.configKey ? values.configKey : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].configKey
                  ? errors.children[index].configKey
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].configKey &&
                Boolean(errors.children[index].configKey)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Position location list</Translate>}
              name={`children[${index}][sorting]`}
              defaultValue={values.sorting ? values.sorting.toString() : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].sorting
                  ? errors.children[index].sorting
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].sorting &&
                Boolean(errors.children[index].sorting)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Complimentary close</Translate>}
              name={`children[${index}][signature]`}
              defaultValue={values.signature ? values.signature : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errorCheck && errors.children[index].signature
                  ? errors.children[index].signature
                  : ''
              }
              error={
                errorCheck &&
                errors.children[index].signature &&
                Boolean(errors.children[index].signature)
              }
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Car dealership</Translate>
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <RepresentedBy
              name={`children[${index}]`}
              touched={
                touched.children && touched.children[index]
                  ? touched.children[index]
                  : ''
              }
              errors={
                errors.children && errors.children[index]
                  ? errors.children[index]
                  : ''
              }
              values={values}
              handleChange={handleChange}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <Tags
              name={`children[${index}]`}
              touched={
                touched.children && touched.children[index]
                  ? touched.children[index]
                  : ''
              }
              errors={
                errors.children && errors.children[index]
                  ? errors.children[index]
                  : ''
              }
              values={values}
              handleChange={handleChange}
            />
            <Input
              type="hidden"
              name={`children[${index}][id]`}
              defaultValue={values.id ? values.id : ''}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { values } = this.props;

    if (!values) {
      return null;
    }

    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={`Standort ${values.address}`}
          subheader={<Translate>{values.tag}</Translate>}
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              sx={{
                '&.expanded': {
                  transform: 'rotate(180deg)',
                },
              }}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>{this.renderFieldset(values)}</CardContent>
        </Collapse>
        <CardActions>
          <Button variant="outlined" color="error" onClick={this.handleRemove}>
            <DeleteIcon />
            <Translate>remove</Translate>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default Child;
