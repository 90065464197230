import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import ACL from '../../components/ACL';
import Translate from '../../components/service/Translate';
import Edit from './components/Edit';
import { isEqual } from 'lodash';
import {
  updateStaff,
  loadStaffs,
  removeStaff,
  loadStaff,
  loadNext,
} from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import { loadLocations } from '../Location/actions';
import { makeStaffByAffiliate } from './selectors';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import RenderCellExpand from '../../components/DataGrid/RenderCellExpand';
import DataGridTable from '../../components/DataGrid/DataGrid';

const INITIAL_GRID_STATE = {
  sorting: {
    sortModel: [{ field: 'id', sort: 'asc' }],
  },
};

class StaffList extends Component {
  static propTypes = {
    updateStaff: PropTypes.func.isRequired,
    loadStaffs: PropTypes.func.isRequired,
    loadLocations: PropTypes.func.isRequired,
    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    removeStaff: PropTypes.func.isRequired,
    affiliateId: PropTypes.number,
    resultset: PropTypes.array,
    staffs: PropTypes.object,
    displaySelectAll: PropTypes.bool,
    docked: PropTypes.bool,
    fitted: PropTypes.bool,
  };

  static defaultProps = {
    displaySelectAll: false,
    docked: false,
    fitted: false,
  };

  state = {
    current: null,
    currentIndex: null,
    mode: 'show',
    deleting: false,
    valid: false,
    sortColumns: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.field),
    sortOrders: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.sort),
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'gender',
        headerName: 'Anrede',
        relation: null,
        modifier: 'translate',
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'firstname',
        headerName: 'Vorname',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'name',
        headerName: 'Name',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'agentCode',
        headerName: 'Kürzel',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'position',
        headerName: 'Position',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'username',
        headerName: 'Benutzername',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'lastLogin',
        headerName: 'Letzte Login',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'printableCopy',
        headerName: 'Belegexemplar',
        relation: null,
        modifier: 'translate',
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders } = this.state;

    this.props.outdateEntity('staff');
    this.setState({ dataLoading: true });

    Promise.all([
      this.props.loadStaffs(sortColumns, sortOrders),
      this.props.loadLocations(),
    ]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextProps.staffs, this.props.staffs) ||
      !isEqual(nextProps.affiliateId, this.props.affiliateId) ||
      !isEqual(nextState.mode, this.state.mode) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextState.state, this.state)
    );
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  submit = () => {
    this.form.submit();
  };

  handleDelete = (form) => {
    const { staffs, resultset, loadStaffs } = this.props;
    const { currentIndex, sortColumns, sortOrders, current } = this.state;

    const promises = [];
    const nextIndex =
      currentIndex >= 0 && currentIndex < resultset.length - 1
        ? resultset[currentIndex + 1]
        : currentIndex > 0
          ? resultset[currentIndex - 1]
          : 0;
    const next = staffs[nextIndex];

    promises.push(this.props.resetEntity('staff', current));
    promises.push(this.props.removeStaff(form, current));

    this.setState({
      deleting: false,
      mode: 'show',
      currentIndex: nextIndex,
      current: next,
    });

    Promise.all(promises).then(() => {
      loadStaffs(sortColumns, sortOrders);
    });
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSubmit = (form) => {
    const { updateStaff, loadStaffs } = this.props;
    const { current, sortColumns, sortOrders, mode } = this.state;

    this.setState({ valid: false });

    return Promise.all([
      updateStaff(form, mode === 'editing' ? current : null),
    ]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        this.props.outdateEntity('staff');
        loadStaffs(sortColumns, sortOrders);
        this.setState({ mode: 'show' });
      }
    });
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  onTouchEdit = (current) => {
    this.setState({ mode: 'editing', valid: false, current: current });
  };

  onTouchAdd = () => {
    this.setState({ mode: 'adding', valid: false });
  };

  onTouchDelete = (current) => {
    this.setState({ deleting: true, current: current });
  };

  onTouchCancel = () => {
    this.setState({ mode: 'show', deleting: false, valid: false });
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { staffs } = this.props;

    if (deleting && current) {
      return (
        <DeleteDialog
          current={staffs[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent = () => {
    const { staffs, fitted, resultset, pagination, embedded } = this.props;
    const { columns, current, mode, dataLoading } = this.state;
    let child = null;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      switch (mode) {
        case 'adding':
          child = (
            <ErrorBoundary>
              <ACL authorized={['ROLE_ADMIN']}>
                <Edit
                  bindSubmitForm={this.bindSubmitForm}
                  onMount={this.handleFormMount}
                  onSubmit={this.handleSubmit}
                  onValidityChange={this.handleValidityChange}
                  staffId={null}
                  onCancel={this.onTouchCancel}
                  submit={this.submit}
                />
              </ACL>
            </ErrorBoundary>
          );
          break;
        case 'editing':
          child = (
            <ErrorBoundary>
              <ACL authorized={['ROLE_ADMIN']}>
                <Edit
                  bindSubmitForm={this.bindSubmitForm}
                  onMount={this.handleFormMount}
                  onSubmit={this.handleSubmit}
                  onValidityChange={this.handleValidityChange}
                  staffId={current}
                  onCancel={this.onTouchCancel}
                  submit={this.submit}
                />
              </ACL>
            </ErrorBoundary>
          );
          break;
        case 'show':
        default:
          return (
            <div>
              <ErrorBoundary>
                <DataGridTable
                  noPaper={fitted}
                  title="Mitarbeiter"
                  rows={staffs}
                  iteratableRows={resultset}
                  columns={columns}
                  pagination={pagination}
                  onEdit={this.onTouchEdit}
                  onAdd={this.onTouchAdd}
                  onDelete={this.onTouchDelete}
                  onLoadNext={this.loadNext}
                  onSortModelChange={this.handleSortModelChange}
                  embedded={embedded}
                  initialState={INITIAL_GRID_STATE}
                />
              </ErrorBoundary>
            </div>
          );
      }
    }

    if (!fitted) {
      return <Paper>{child}</Paper>;
    }

    return child;
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getStaffByAffiliate = makeStaffByAffiliate();

  return (state, props) => {
    const {
      entities: { staff },
      resultsets: { staff: resultset },
      pagination: { staff: pagination },
    } = state;

    if (props.affiliateId) {
      const staffs = getStaffByAffiliate(state, props);
      return {
        staffs: staffs,
        //resultset: resultset,
        pagination: pagination,
      };
    }

    return {
      staffs: staff,
      resultset: resultset,
      pagination: pagination,
    };
  };
};

export default connect(makeMapStateToProps, {
  resetEntity,
  outdateEntity,
  updateStaff,
  loadLocations,
  loadStaffs,
  loadStaff,
  loadNext,
  removeStaff,
})(StaffList);
