import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Translate from '../service/Translate';
import DateTime from '../service/DateTime';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value, modifier } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  const renderValue = (data, modifier = null) => {
    switch (modifier) {
      case 'translate':
        data = (
          <Translate key={`dt-translate-${Math.random()}`}>{data}</Translate>
        );
        break;
      case 'datetime':
        data = (
          <DateTime
            format="dd.mm.yyyy HH:MM"
            key={`dt-translate-${Math.random()}`}
          >
            {data}
          </DateTime>
        );
        break;
      case 'date':
        data = (
          <DateTime format="dd.mm.yyyy" key={`dt-translate-${Math.random()}`}>
            {data}
          </DateTime>
        );
        break;
      case 'image':
        data = (
          <img
            src={data}
            alt="Keine Vorschau vorhanden"
            width="358"
            height="280"
          ></img>
        );
        break;
    }

    return data;
  };

  return (
    <div
      style={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div
        ref={cellValue}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {modifier ? renderValue(value, modifier) : value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width }}
        >
          <Paper
            sx={{ m: 0, p: 0, minHeight: wrapper.current.offsetHeight - 3 }}
            elevation={1}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {modifier ? renderValue(value, modifier) : value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

const renderCellExpand = (params) => {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.computedWidth}
      modifier={params.colDef.modifier ? params.colDef.modifier : null}
    />
  );
};

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default renderCellExpand;
