import React, { Component } from 'react';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { isEmpty, isEqual } from 'lodash';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import { Input, MenuItem, Paper, TextField } from '@mui/material';
import {
  fetchToken,
  createCampaignConfigParam,
  updateCampaignConfigParam,
  fetchCampaignConfigParamById,
  selectCampaignConfigParamById,
} from '../CampaignConfigParamSlice';
import ValidationErrors from '../../../ValidationErrors';
import { fetchAllCampaigns } from '../../Campaign/CampaignSlice';
import Grid from '@mui/material/Unstable_Grid2';
import { compose } from 'redux';
import { makeCampaignsBySingularSeason } from '../../Campaign/selectors';

const validationSchema = Yup.object().shape({
  config: Yup.string().required(ValidationErrors.required),
  campaign: Yup.string().required(ValidationErrors.required),
});

class CampaignConfigParamEdit extends Component {
  static defaultProps = {};
  state = {
    dataLoading: true,
  };

  componentDidMount = () => {
    const {
      campaignConfigParamId,
      fetchCampaignConfigParamById,
      fetchToken,
      fetchAllCampaigns,
      duplicate,
    } = this.props;
    let promises = [];

    if (campaignConfigParamId) {
      promises.push(
        fetchCampaignConfigParamById({ id: campaignConfigParamId }),
        fetchAllCampaigns(),
      );
      if (duplicate) {
        promises.push(fetchToken());
      }
    } else {
      promises.push(fetchToken(), fetchAllCampaigns());
    }
    this.setState({ dataLoading: true });

    Promise.all(promises).then(
      // eslint-disable-next-line no-unused-vars
      (value) => {
        this.setState({ dataLoading: false });
      },
      // eslint-disable-next-line no-unused-vars
      (error) => {
        this.setState({ dataLoading: false });
      },
    );
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(
        nextProps.campaignConfigParamId,
        this.props.campaignConfigParamId,
      ) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextProps.campaignConfigParam, this.props.campaignConfigParam) ||
      !isEqual(nextProps.duplicate, this.props.duplicate) ||
      !isEqual(
        nextProps.campaignsBySingularSeason,
        this.props.campaignsBySingularSeason,
      ) ||
      !isEqual(nextProps.token, this.props.token)
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.campaignConfigParamId !== prevProps.campaignConfigParamId) {
      this.props.fetchCampaignConfigParamById({
        id: this.props.campaignConfigParamId,
      });
    }
  }

  renderCampaigns = (item, index) => {
    return (
      <MenuItem key={index} value={item.id}>
        {
          <Translate>
            {item.primaryText + (item.crmId ? ' - ' + item.crmId : '')}
          </Translate>
        }
      </MenuItem>
    );
  };

  handleSubmit = async (form) => {
    const {
      updateCampaignConfigParam,
      campaignConfigParamId,
      campaignConfigParam,
      duplicate,
      createCampaignConfigParam,
    } = this.props;
    let response;

    if (campaignConfigParamId && !duplicate) {
      response = await updateCampaignConfigParam({
        id: campaignConfigParamId,
        data: form,
      });
    } else {
      response = await createCampaignConfigParam({ data: form });
    }

    if (response.error) {
      return;
    }

    if (campaignConfigParam) {
      this.props.navigate(
        '/campaignconfigparam/campaign/' + campaignConfigParam.campaign,
      );
    } else {
      this.props.navigate('/campaignconfigparam');
    }
  };

  onTouchCancel = () => {
    const { campaignConfigParam } = this.props;

    if (campaignConfigParam) {
      this.props.navigate(
        '/campaignconfigparam/campaign/' + campaignConfigParam.campaign,
      );
      return;
    }

    this.props.navigate('/campaignconfigparam');
  };

  renderFieldset = (props) => {
    const { campaignsBySingularSeason } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;
    this.formikProps = props;

    return (
      <Grid container spacing={3}>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>config</Translate>}
            name="config"
            value={values.config ? values.config : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.config ? errors.config : ''}
            error={touched.config && Boolean(errors.config)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name="campaign"
            label={<Translate>Kampagne</Translate>}
            value={values.campaign ? values.campaign : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.campaign ? errors.campaign : ''}
            error={touched.campaign && Boolean(errors.campaign)}
          >
            {campaignsBySingularSeason.map(this.renderCampaigns)}
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>param</Translate>}
            name="param"
            multiline
            fullWidth
            value={values.param ? values.param : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.param ? errors.param : ''}
            error={touched.param && Boolean(errors.param)}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { campaignConfigParamId, campaignConfigParam, token, duplicate } =
      this.props;
    const { dataLoading } = this.state;

    if (dataLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }

    if (campaignConfigParamId && isEmpty(campaignConfigParam)) {
      return null;
    }

    if (!campaignConfigParamId && !token) {
      return null;
    }

    let initialValues = {};

    if (!campaignConfigParam && token) {
      initialValues.updateToken = token;
    }

    if (!isEmpty(campaignConfigParam)) {
      initialValues = { ...campaignConfigParam };
    }

    if (duplicate) {
      delete initialValues.campaign;
      delete initialValues.local;
      initialValues.id = null;
      initialValues.updateToken = token;
    }

    let headline = campaignConfigParam?.config ? (
      campaignConfigParam.config
    ) : (
      <Translate>New param</Translate>
    );

    return (
      <Paper>
        <Form
          onCancel={this.onTouchCancel}
          validationSchema={validationSchema}
          headline={headline}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          name="campaign_config_param"
          renderFieldset={this.renderFieldset}
        />
      </Paper>
    );
  }
}

const makeMapStateToProps = () => {
  const getCampaignsBySingularSeason = makeCampaignsBySingularSeason();

  return (state, ownProps) => {
    const params = ownProps?.match?.params;
    const mode = params.mode;

    const campaignConfigParamId = params.campaignConfigParamId
      ? parseInt(params.campaignConfigParamId)
      : null;

    /*const {
      entities: { campaign: campaigns, season: seasons },
      forms: { campaignConfigParam: form },
    } = state;*/

    const {
      campaignConfigParam: { updateToken: updateToken },
    } = state;

    const campaignsBySingularSeason = getCampaignsBySingularSeason(state);
    const campaignConfigParamById = selectCampaignConfigParamById(
      state,
      campaignConfigParamId,
    );

    return {
      duplicate: mode === 'duplicate',
      campaignConfigParamId: campaignConfigParamId,
      campaignsBySingularSeason: campaignsBySingularSeason,
      campaignConfigParam: campaignConfigParamById,
      token: updateToken,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    fetchCampaignConfigParamById,
    updateCampaignConfigParam,
    createCampaignConfigParam,
    fetchAllCampaigns,
    fetchToken,
  }),
);

export default enhance(CampaignConfigParamEdit);
