import React, { Component } from 'react';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { concat, find, isEmpty, isEqual, keys, merge, pick } from 'lodash';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import {
  IconButton,
  Input,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { loadSignee, initForm } from '../actions';
import ValidationErrors from '../../../ValidationErrors';
import ACL from '../../../components/ACL';
import Upload from '../../../components/form/Upload';
import Signature from './Signature';
import ContentAdd from '@mui/icons-material/Add';
import { makeSigneeWithSignatures } from '../selectors';
import { loadLocations } from '../../Location/actions';
import FormHelperText from '@mui/material/FormHelperText';
import { loadPrintableCopyGroups, loadRoles } from '../../Staff/actions';
import { loadMailings } from '../../Mailing/actions';
import { loadAffiliates } from '../../Affiliate/actions';
import { outdateEntity } from '../../../actions';
import { compose } from 'redux';
import Grid from '@mui/material/Unstable_Grid2';

const validationSchema = Yup.object().shape({
  gender: Yup.string().nullable(),
  firstname: Yup.string().required(ValidationErrors.required),
  name: Yup.string().required(ValidationErrors.required),
  fileSignature: Yup.mixed().when('signature', {
    is: (val) => !val,
    then: () => Yup.mixed().required(ValidationErrors.requiredSignatureFile),
  }),
  position: Yup.string().nullable(),
  mailingSignatures: Yup.array()
    .defined(ValidationErrors.requiredSignature)
    .of(
      Yup.object().shape({
        width: Yup.number()
          .typeError(ValidationErrors.typeErrorNumber)
          .required(ValidationErrors.required),
        height: Yup.number()
          .typeError(ValidationErrors.typeErrorNumber)
          .required(ValidationErrors.required),
        x: Yup.number()
          .typeError(ValidationErrors.typeErrorNumber)
          .required(ValidationErrors.required),
        y: Yup.number()
          .typeError(ValidationErrors.typeErrorNumber)
          .required(ValidationErrors.required),
        legend: Yup.string().required(ValidationErrors.required),
        offsetLegendX: Yup.number()
          .nullable()
          .typeError(ValidationErrors.typeErrorNumber),
        offsetLegendY: Yup.number()
          .nullable()
          .typeError(ValidationErrors.typeErrorNumber),
        mailing: Yup.string().required(ValidationErrors.required),
        affiliate: Yup.string().nullable(),
        fallback: Yup.bool(),
        position: Yup.number()
          .typeError(ValidationErrors.typeErrorNumber)
          .required(ValidationErrors.required),
        location: Yup.string().nullable(),
        mode: Yup.string()
          .oneOf(['voucher', 'standard'], ValidationErrors.requiredSelect)
          .required(ValidationErrors.required),
      }),
    ),
});

class SigneeEdit extends Component {
  static propTypes = {
    loadSignee: PropTypes.func.isRequired,
    token: PropTypes.string,
  };

  static defaultProps = {
    layoutId: null,
    values: {},
  };

  state = {
    tagsLoaded: false,
    emptyEditorError: false,
    mailingSignatures: [],
  };

  componentDidMount = () => {
    this.props.outdateEntity('mailing');
    const promises = [
      this.props.loadPrintableCopyGroups(),
      this.props.loadRoles(),
      this.props.loadLocations(
        [],
        [],
        ['affiliateHead', 'headquarter', 'primaryText'],
      ),
      this.props.loadMailings(),
      this.props.loadAffiliates(null, null, null, ['id', 'primaryText']),
    ];

    if (this.props.signeeId && this.props.mode === 'edit') {
      promises.push(this.props.loadSignee(this.props.signeeId));
    } else {
      promises.push(this.props.initForm());
    }

    this.setState({ dataLoading: true });

    Promise.all(promises).then(
      () => {
        if (this.props.signeeId) {
          this.setState({ dataLoading: false });
        } else {
          this.setState({ dataLoading: false });
        }
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.signeeId, this.props.signeeId) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(this.props.signee, nextProps.signee) ||
      !isEqual(nextState.mailingSignatures, this.state.mailingSignatures) ||
      !isEqual(nextProps.token, this.props.token)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (!isEqual(this.state.dataLoading, prevState.dataLoading) ||
        !isEqual(this.props.signee, prevProps.signee)) &&
      !isEmpty(this.props.signee)
    ) {
      this.setState({
        mailingSignatures: this.props.signee.mailingSignatures,
      });
    }
  }

  loadSigneeById = (id) => {
    this.setState({ dataLoading: true });
    Promise.all([this.props.loadSignee(id)]).then(
      () => {
        this.setState({
          dataLoading: false,
          mailingSignatures: this.props.signee.mailingSignatures,
        });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  handleSignatureRemove = (childId) => {
    let newMailingSignatures = this.state.mailingSignatures;
    newMailingSignatures.splice(childId, 1);
    this.setState({ mailingSignatures: newMailingSignatures });
  };

  handleSignatureAdd = () => {
    this.setState({
      mailingSignatures: concat(this.state.mailingSignatures, [
        `new_${this.state.mailingSignatures.length + 1}`,
      ]),
    });
  };

  renderMailingSignature = (props, signatureId, index) => {
    if (signatureId) {
      let values = {};
      const { signeeId, signatures } = this.props;
      const { touched, errors, handleChange, handleBlur, setFieldValue } =
        props;
      const template = {
        width: 130,
        height: 73,
        x: 0,
        y: 0,
        legend: 'firstname name',
        offsetLegendX: 0,
        offsetLegendY: 0,
        position: 1,
        mode: 'standard',
      };

      if (
        typeof signatureId === 'string' &&
        -1 !== signatureId.indexOf('new_')
      ) {
        const element =
          isEmpty(props.values.mailingSignatures) ||
          typeof props.values.mailingSignatures[index] === 'undefined'
            ? null
            : props.values.mailingSignatures[index];
        values = element
          ? merge({}, element, { mailingSignatures: [] })
          : merge(
              {},
              isEmpty(signatures) ? template : signatures[keys(signatures)[0]],
              { mailingSignatures: [] },
            );
        values.id = undefined;
        values.mailing = '';
        values.location = '';
        values.affiliate = undefined;
      } else {
        const signature = find(props.values.mailingSignatures, {
          id: signatureId,
        });
        values = merge(
          {},
          props.values.mailingSignatures && signature
            ? signature
            : signatures[signatureId],
          {},
        );
      }

      return (
        <Grid xs={12} key={index}>
          <Signature
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            key={`signee-${signeeId}-signature-${index}`}
            signeeId={signeeId}
            signatureId={signatureId}
            values={values}
            onRemove={(childId) => {
              this.handleSignatureRemove(childId);
            }}
            onAdd={this.handleSignatureAdd}
            index={index}
            submitCount={props.submitCount}
            formikProps={props}
          />
        </Grid>
      );
    }
  };

  renderMailingSignatures = (props) => {
    const { mailingSignatures } = this.state;
    const { values, errors, touched } = props;

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">
            <Translate>Mailing settings</Translate>
            <IconButton
              aria-label={<Translate>add</Translate>}
              onClick={this.handleSignatureAdd}
              size="large"
            >
              <ContentAdd />
            </IconButton>
          </Typography>
        </Grid>
        {isEmpty(values.mailingSignatures) &&
        touched.mailingSignatures &&
        Boolean(errors.mailingSignatures) ? (
          <Grid xs={12}>
            <FormHelperText error={true}>
              {errors.mailingSignatures}
            </FormHelperText>
          </Grid>
        ) : null}

        {mailingSignatures.map((item, index) =>
          this.renderMailingSignature(props, item, index),
        )}
      </>
    );
  };

  renderFieldset = (props) => {
    const { signeeId } = this.props;
    const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
      props;

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              select
              name="gender"
              label={<Translate>gender</Translate>}
              value={values.gender ? values.gender : ''}
              onChange={handleChange('gender')}
              onBlur={handleBlur}
              helperText={touched.gender ? errors.gender : ''}
              error={touched.gender && Boolean(errors.gender)}
            >
              <MenuItem value={'female'}>Frau</MenuItem>
              <MenuItem value={'male'}>Herr</MenuItem>
              <MenuItem value={'company'}>Firma</MenuItem>
            </TextField>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              name="name"
              defaultValue={values.name ? values.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>firstname</Translate>}
              name="firstname"
              defaultValue={values.firstname ? values.firstname : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.firstname ? errors.firstname : ''}
              error={touched.firstname && Boolean(errors.firstname)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>position</Translate>}
              name="position"
              defaultValue={values.position ? values.position : ''}
              onChange={handleChange}
              helperText={touched.position ? errors.position : ''}
              error={touched.position && Boolean(errors.position)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Signature</Translate>
            </Typography>
          </Grid>
          {signeeId ? (
            <Grid xs={12}>
              <object
                data={Routing.generate(
                  'signee_show_signature',
                  { id: signeeId },
                  true,
                )}
                type="application/pdf"
                width={'100%'}
                height={'251px'}
              />
            </Grid>
          ) : null}
          <Grid xs={12}>
            <Upload
              label={
                <Translate wildcards={{ '%type%': 'pdf-x3' }}>
                  Upload file %type%
                </Translate>
              }
              name="fileSignature"
              setFieldValue={setFieldValue}
            />
          </Grid>
          {!values.signature &&
          touched.fileSignature &&
          Boolean(errors.fileSignature) ? (
            <Grid xs={12}>
              <FormHelperText error={true}>
                {errors.fileSignature}
              </FormHelperText>
            </Grid>
          ) : null}
          {values.fileSignature ? (
            <Grid xs={12}>
              <FormHelperText>
                Ausgewählte Datei: {values.fileSignature.name}
              </FormHelperText>
            </Grid>
          ) : null}
          {this.renderMailingSignatures(props)}
          <Input
            type="hidden"
            name="signature"
            value={values.signature ? values.signature : ''}
          />
        </Grid>
      </>
    );
  };

  render() {
    const {
      signeeId,
      signee,
      onSubmit,
      token,
      onCancel,
      signeeWithSignatures,
    } = this.props;
    const { dataLoading } = this.state;
    const defaultValues = {};
    let initialValues = {};

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if (signeeId && isEmpty(signee)) {
      return null;
    }

    if (!signeeId && isEmpty(token)) {
      return null;
    }

    if (!isEmpty(token)) {
      defaultValues.updateToken = token;
      initialValues = defaultValues;
    }

    if (!isEmpty(signeeWithSignatures)) {
      initialValues = signeeWithSignatures;
      initialValues.location = initialValues.location
        ? initialValues.location
        : '';
    }

    let headline = !isEmpty(signeeWithSignatures) ? (
      signeeWithSignatures.primaryText
    ) : (
      <Translate>New signee</Translate>
    );

    return (
      <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
        <Form
          onCancel={onCancel}
          validationSchema={validationSchema}
          headline={headline}
          values={{}}
          initialValues={
            !isEmpty(signeeWithSignatures)
              ? signeeWithSignatures
              : defaultValues
          }
          onSubmit={onSubmit}
          name="signee"
          renderFieldset={this.renderFieldset}
        />
      </ACL>
    );
  }
}

function makeMapStateToProps() {
  const getSigneeWithSignatures = makeSigneeWithSignatures();

  return (state, props) => {
    const {
      entities: { signee, signature },
      forms: { signee: form },
    } = state;

    const {
      params: { signeeId, mode },
    } = props.match;

    if (!isEmpty(signee) && signeeId && signee[signeeId]) {
      return {
        mode: mode,
        signeeId: signeeId ? signeeId : null,
        signee: signee[signeeId],
        mailingSignatures: signee[signeeId].mailingSignatures,
        signatures: pick(signature, signee[signeeId].mailingSignatures),
        token: !isEmpty(form) ? form.updateToken : null,
        signeeWithSignatures: getSigneeWithSignatures(state, props),
      };
    }

    return {
      mode: mode,
      signeeId: signeeId ? signeeId : null,
      signatures: null,
      token: !isEmpty(form) ? form.updateToken : null,
      signeeWithSignatures: {},
    };
  };
}

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    loadSignee,
    loadLocations,
    initForm,
    loadPrintableCopyGroups,
    loadRoles,
    loadMailings,
    loadAffiliates,
    outdateEntity,
  }),
);

export default enhance(SigneeEdit);
