import React, { Component } from 'react';
import { Input, TextField, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { initForm, loadLetter } from '../actions';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'lodash';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import Grid from '@mui/material/Unstable_Grid2';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.required),
  config: Yup.string().required(ValidationErrors.required),
  sender: Yup.string().required(ValidationErrors.required),
  premium: Yup.string().nullable(),
  address: Yup.string().nullable(),
  informationHeadline: Yup.string().nullable(),
  informationText: Yup.string().nullable(),
  informationDate: Yup.string().nullable(),
  business: Yup.string().nullable(),
});

class Letter extends Component {
  static propTypes = {
    letterId: PropTypes.number,
    loadLetter: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    initForm: PropTypes.func,
  };

  static defaultProps = {
    letterId: null,
    values: {},
  };

  state = {};

  componentDidMount = () => {
    if (this.props.letterId) {
      this.props.loadLetter(this.props.letterId);
    } else {
      this.props.initForm();
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.letterId !== prevProps.letterId) {
      this.props.loadLetter(this.props.letterId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.letterId, this.props.letterId) ||
      !isEqual(nextProps.letter, this.props.letter) ||
      !isEqual(this.props.token, nextProps.token) ||
      !isEqual(this.props.form, nextProps.form)
    );
  };

  renderFieldset = (props) => {
    const { bindSubmitForm } = this.props;
    const { values, handleChange, handleBlur, touched, errors } = props;

    bindSubmitForm(props.submitForm);

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container spacing={3}>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              name="name"
              defaultValue={values.name ? values.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Config key</Translate>}
              name="config"
              defaultValue={values.config ? values.config : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.config ? errors.config : ''}
              error={touched.config && Boolean(errors.config)}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Address window</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Sender</Translate>}
              defaultValue={values.sender ? values.sender : ''}
              name="sender"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.sender ? errors.sender : ''}
              error={touched.sender && Boolean(errors.sender)}
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Premium tag</Translate>}
              defaultValue={values.premium ? values.premium : ''}
              name="premium"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.premium ? errors.premium : ''}
              error={touched.premium && Boolean(errors.premium)}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              id="address"
              label={<Translate>Addressee</Translate>}
              defaultValue={values.address ? values.address : ''}
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.address ? errors.address : ''}
              error={touched.address && Boolean(errors.address)}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Sidebar</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Headline</Translate>}
              defaultValue={
                values.informationHeadline ? values.informationHeadline : ''
              }
              name="informationHeadline"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.informationHeadline ? errors.informationHeadline : ''
              }
              error={
                touched.informationHeadline &&
                Boolean(errors.informationHeadline)
              }
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Running text</Translate>}
              defaultValue={
                values.informationText ? values.informationText : ''
              }
              name="informationText"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.informationText ? errors.informationText : ''}
              error={touched.informationText && Boolean(errors.informationText)}
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label={<Translate>Date</Translate>}
              defaultValue={
                values.informationDate ? values.informationDate : ''
              }
              name="informationDate"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.informationDate ? errors.informationDate : ''}
              error={touched.informationDate && Boolean(errors.informationDate)}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              <Translate>Footer</Translate>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              id="business"
              label={<Translate>Business</Translate>}
              defaultValue={values.business ? values.business : ''}
              name="business"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.business ? errors.business : ''}
              error={touched.business && Boolean(errors.business)}
              multiline
            />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { letter, letterId, onSubmit, OnMount, token, form } = this.props;
    const { ...defaultValues } = this.props.values;

    if (letterId && isEmpty(letter)) {
      // if letterId is set letter should not be empty
      return null;
    }

    if (!letterId && isEmpty(form)) {
      // if letterId is missing form should not be empty
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    return (
      <Form
        validationSchema={validationSchema}
        disableToolbar={true}
        onMount={OnMount}
        onSubmit={onSubmit}
        initialValues={!isEmpty(letter) ? letter : defaultValues}
        name="letter"
        renderFieldset={this.renderFieldset}
        values={letter}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const letterId = ownProps.letterId;

  const {
    entities: { letter: letters },
    forms: { letter: form },
  } = state;

  return {
    letter: letterId ? letters[letterId] : {},
    token: !isEmpty(form) ? form.updateToken : '',
    form: form,
  };
}

export default connect(mapStateToProps, { loadLetter, initForm })(Letter);
